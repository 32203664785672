import dayjs from "dayjs";
import { useState } from "react";
import { getDocumentRawContents } from "../../../api/document";
import { Actions, Features, useAnalyticsContext } from "./analytics-context";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast, { UseMetriportToast } from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { isAxiosError } from "axios";
import { capture } from "../../../shared/capture";
import { handleNetworkErrorWithTryAgainToast } from "../../error/utils";
import { axiosErrorCodes } from "../../shared/constants";

const EXPLAIN_TOAST_DURATION = dayjs.duration(15, "second").asMilliseconds();
const XML_TIMEOUT = dayjs.duration(25, "second").asMilliseconds();

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

export type OnDownloadFileParams = {
  docId: string;
  docFilename: string;
  docExtension?: string;
  conversionType?: "html" | "pdf";
};

type UseDownloadFile = {
  isDownloading: isDownloading;
  onDownloadFile: (params: OnDownloadFileParams) => Promise<void>;
};

export function useDownloadFile(): UseDownloadFile {
  const toast = useMetriportToast();
  const metriportApi = useMetriportApi();
  const Analytics = useAnalyticsContext();

  const [isDownloading, setIsDownloading] = useState<isDownloading>({});

  async function onDownloadFile({
    docId,
    docFilename,
    docExtension,
    conversionType,
  }: OnDownloadFileParams): Promise<void> {
    Analytics.emit(Actions.download, Features.document);
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: true,
        type: conversionType ?? "xml",
      },
    });
    function reset() {
      setIsDownloading({
        ...isDownloading,
        [docId]: {
          downloading: false,
        },
      });
    }
    if (conversionType) {
      toast.info({
        title: "Hold tight... This might take up to 30 seconds.",
        duration: EXPLAIN_TOAST_DURATION,
      });
    }
    try {
      const { url } = await metriportApi.getDocumentUrl(docFilename, conversionType);
      let skipS3Link = false;
      if (!conversionType) {
        const contents = await getDocumentRawContents(url, XML_TIMEOUT);
        if (contents.includes("<?xml-stylesheet")) {
          skipS3Link = true;
          toast.info({
            title: "This XML is not viewable in the browser, proceeding with download...",
            duration: EXPLAIN_TOAST_DURATION,
          });
        }
        const contentsBlob = new Blob([contents], { type: docExtension });
        const downloadA = document.createElement("a");
        downloadA.href = URL.createObjectURL(contentsBlob);
        downloadA.download = docFilename;
        downloadA.target = "_blank";
        document.body.appendChild(downloadA);
        downloadA.click();
        document.body.removeChild(downloadA);
      }
      if (!skipS3Link) {
        const a = document.createElement("a");
        a.href = url;
        a.download = docFilename;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      const { handled } = handleTimeoutOrNetworkError(error, toast);
      if (!handled) {
        const msg = "Failed to download document.";
        capture.error(error, {
          extra: { docId, docExtension, context: `patient.file.download`, conversionType, error },
        });
        toast.error({ title: msg });
      }
    } finally {
      reset();
    }
  }

  return { onDownloadFile, isDownloading };
}

function handleTimeoutOrNetworkError(
  error: unknown,
  toast: UseMetriportToast
): { handled: boolean } {
  if (!isAxiosError(error)) return { handled: false };
  if (error.code === axiosErrorCodes.TIMEOUT) {
    toast.error({
      title:
        "The document may be too large to download, please use the API to get the contents or try again. If the issue persists, please reach out to support@metriport.com.",
      duration: EXPLAIN_TOAST_DURATION,
    });
    return { handled: true };
  }
  return handleNetworkErrorWithTryAgainToast(error, toast);
}
