import { Flex, Icon, Text } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useCopyToClipboard } from "usehooks-ts";
import useMetriportToast from "../shared-logic/useMetriportToast";

export function NameCopyContainer({
  value,
  entity,
  fontSize = "sm",
}: {
  value: string | undefined;
  entity: string;
  fontSize?: string;
}) {
  const toast = useMetriportToast();
  const [, copy] = useCopyToClipboard();

  if (!value) return null;

  return (
    <Flex
      onClick={() => {
        copy(value);
        toast.success({
          title: `Copied ${entity} to clipboard`,
        });
      }}
      cursor={"pointer"}
      alignItems={"center"}
    >
      <Text fontSize={fontSize}>{value}</Text>
      <Icon ml={2} as={FiCopy} />
    </Flex>
  );
}
