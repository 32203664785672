import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MrFilterSetting } from "../../../api/settings";
import { MappedConsolidatedResources } from "../shared-logic/consolidated-context/reducer";
import { getRefAndUpdateHeight } from "../shared-logic/elementHeight";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { fhirSections } from "./fhir-sections/sections";
import { getAiSummaryFromMappedConsolidated } from "./fhir-sections/summaries/content";

const MAX_TABS_SHOWN = 8;

type TabOption = {
  index: number;
  display: string;
  id: string;
};

export function PatientTabs({
  filters,
  mappedConsolidated,
  headerHeight = 0,
  isIFrame = false,
  isLimited = false,
}: {
  filters?: MrFilterSetting[];
  mappedConsolidated?: MappedConsolidatedResources | undefined;
  headerHeight?: number;
  isIFrame?: boolean;
  isLimited?: boolean;
}) {
  const [tabsHeight, setTabsHeight] = useState<number>(0);
  const [index, setIndex] = useState(0);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { elementRef, updateHeight } = getRefAndUpdateHeight(setTabsHeight);
  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const aiSummary = getAiSummaryFromMappedConsolidated(mappedConsolidated);

  const tabOptions = generateTabOptions(filters, aiSummary);
  const maxTabsShown =
    useBreakpointValue(
      {
        base: 4,
        md: isLimited ? 5 : 3,
        lg: isLimited ? 7 : 5,
        xl: isLimited ? MAX_TABS_SHOWN : 7,
        "2xl": MAX_TABS_SHOWN,
      },
      { ssr: false }
    ) ?? 0;

  const totalOffset = tabsHeight + headerHeight;

  useEffect(() => {
    const onScroll = () => {
      if (isManualScroll) {
        return;
      }
      const index = setIndexIfInFrame(window.scrollY, tabOptions, totalOffset);

      if (index === undefined) {
        return;
      }

      if (index >= maxTabsShown) {
        setIndex(maxTabsShown);
        return;
      }

      setIndex(index);
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    window.addEventListener("wheel", () => setIsManualScroll(false), { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("wheel", () => setIsManualScroll(false));
    };
  }, [isManualScroll, tabOptions, maxTabsShown, totalOffset]);

  return (
    <Tabs
      ref={elementRef}
      index={index}
      onChange={index => {
        if (index < maxTabsShown) {
          setIsManualScroll(true);
          scrollToIndex(index, tabOptions, totalOffset, isIFrame);
          setIndex(index);
        }
      }}
      py={3}
      size={isLimited ? "xs" : "md"}
      variant="soft-rounded"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <TabList overflowX={"scroll"} justifyContent={maxTabsShown > 0 ? "left" : "right"}>
        {tabOptions
          .filter(tab => tab.index < maxTabsShown)
          .map((tab, i) => {
            return (
              <Tab
                color={useColorModeValue("black", "white")}
                _selected={{ color: "white", bg: METRIPORT_PRIMARY }}
                whiteSpace={"nowrap"}
                key={tab.index}
                cursor={"pointer"}
                py={isLimited ? 0 : 4}
                px={isLimited ? 4 : 6}
                mr={4}
                ml={i === 0 ? 0 : 4}
                fontSize={isLimited ? "xs" : "md"}
              >
                {tab.display}
              </Tab>
            );
          })}
        <Tab _selected={{ bg: METRIPORT_PRIMARY }}>
          <Menu placement={"bottom"} isOpen={isOpen}>
            <MenuButton
              fontWeight={"normal"}
              bg={"transparent"}
              color={index >= maxTabsShown ? "white" : undefined}
              _active={{ bg: "transparent" }}
              _hover={{ bg: "transparent" }}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              as={Button}
              size={isLimited ? "xs" : "md"}
            >
              Other
            </MenuButton>
            <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
              {tabOptions
                .filter(tab => tab.index >= maxTabsShown)
                .map((tab, i) => {
                  return (
                    <MenuItem
                      color={useColorModeValue("black", "white")}
                      key={i}
                      onClick={() => {
                        setIsManualScroll(true);
                        scrollToIndex(tab.index, tabOptions, totalOffset, isIFrame);
                        setIndex(maxTabsShown);
                        onClose();
                      }}
                      fontSize={isLimited ? "xs" : "md"}
                    >
                      {tab.display}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>
        </Tab>
      </TabList>
    </Tabs>
  );
}

function generateTabOptions(mrFilterSettings?: MrFilterSetting[], aiSummary?: string): TabOption[] {
  const tabOptions: TabOption[] = [];

  if (aiSummary) {
    tabOptions.push({ index: 0, id: "ai-summary", display: "AI Summary" });
  }

  if (mrFilterSettings) {
    mrFilterSettings.forEach((filter, i) => {
      const display = fhirSections.find(section => section.key === filter.key)?.title;
      if (display) {
        tabOptions.push({
          index: aiSummary ? i + 1 : i,
          id: filter.key,
          display,
        });
      }
    });
  } else {
    fhirSections.forEach((section, i) => {
      tabOptions.push({
        index: aiSummary ? i + 1 : i,
        id: section.key,
        display: section.title,
      });
    });
  }

  return tabOptions;
}

function setIndexIfInFrame(
  scrollOffset: number,
  tabOptions: { display: string; id: string }[],
  totalOffset: number
): number | undefined {
  const currentOffset = scrollOffset + totalOffset;

  for (let i = 0; i < tabOptions.length; i++) {
    const tab = tabOptions[i];
    if (!tab) continue;
    const element = document.getElementById(tab.id);
    if (!element) {
      continue;
    }

    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - totalOffset;

    if (currentOffset >= offsetPosition && currentOffset < offsetPosition + element.clientHeight) {
      return i;
    }
  }
  return undefined;
}

function scrollToIndex(
  index: number,
  tabOptions: { display: string; id: string }[],
  totalOffset: number,
  isIFrame = false
) {
  const tab = tabOptions[index];
  if (!tab) return;
  const element = document.getElementById(tab.id);
  if (!element) {
    return;
  }

  const elementPosition = element.getBoundingClientRect().top;
  const padding = 5;
  const offsetPosition = elementPosition + window.pageYOffset - totalOffset - padding;

  window.scrollTo({
    top: offsetPosition,
    behavior: isIFrame ? undefined : "smooth",
  });
}
