import { Bundle, Resource } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../consolidated-context/reducer";

export function groupBundleByResourceType(bundle: Bundle<Resource>) {
  const mappedResources: MappedConsolidatedResources = {};

  bundle.entry?.forEach(entry => {
    const resource = entry.resource;
    if (resource) {
      if (!mappedResources[resource.resourceType]) {
        mappedResources[resource.resourceType] = {};
      }

      const mappedResource = mappedResources[resource.resourceType];

      if (mappedResource && resource.id) {
        mappedResource[resource.id] = resource;
      }
    }
  });

  return mappedResources;
}
