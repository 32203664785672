import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  InputGroup,
  InputLeftAddon,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Address,
  addressSchema,
  DriverLicenseIdentifier,
  patientCreateSchema,
  PatientDTO,
  usStateSchema,
  Facility,
  usStateForAddressSchema,
} from "@metriport/api-sdk";
import { isValid } from "driver-license-validator";
import { get } from "lodash";
import { useFieldArray } from "react-hook-form";
import { z } from "zod";
import { DEFAULT_COUNTRY } from "../../../domain/countries";
import { Button } from "./button";
import Constants from "../../shared/constants";
import DrawerForm from "../../shared/form/Drawer";
import { Input } from "../../shared/form/Input";
import { Select } from "../../shared/form/Select";
import { Label } from "../../shared/Label";
import { emptyStringToUndefined, mapToOptions } from "../../shared/util";

export const DRIVERS_LICENSE = "driversLicense" as const;

const contactSchemaNonNull = z.object({
  phone: z.preprocess(emptyStringToUndefined, z.string().length(10).or(z.undefined())),
  email: z.preprocess(emptyStringToUndefined, z.string().email().or(z.undefined())),
});
type ContactNonNull = z.infer<typeof contactSchemaNonNull>;
const patientFormSchema = patientCreateSchema
  .omit({ personalIdentifiers: true })
  .merge(z.object({ externalId: z.string().optional() }))
  .merge(z.object({ facilityId: z.string() }))
  .merge(
    z.object({
      driversLicense: z
        .object({
          value: z.string(),
          state: z.string(),
        })
        .superRefine((input, ctx) => {
          if (!input) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["value"],
              message: "Missing input",
            });
            return;
          }
          const value = input.value.trim();
          const state = input.state.trim();
          if (value.length <= 0) return; // nothing to check
          if (state.length <= 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["state"],
              message: "Select a State",
            });
            return;
          }
          const valid = isValid(value, { states: state });
          if (!valid) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["value"],
              message: "Invalid driver's license",
            });
            return;
          }
        }),
    })
  )
  .omit({ contact: true })
  .merge(
    z.object({
      contact: z.array(contactSchemaNonNull),
    })
  )
  .omit({ address: true })
  .merge(
    z.object({
      address: z.array(addressSchema).nonempty(),
    })
  );
export type PatientFormSchema = z.infer<typeof patientFormSchema>;

export function PatientForm({
  isOpen,
  selectedPatient,
  facilities,
  isSubmitting,
  actions,
}: {
  isOpen: boolean;
  facilities: Facility[] | undefined;
  selectedPatient?: PatientDTO;
  isSubmitting: boolean;
  actions: {
    onCreateOrUpdatePatient: (patient: PatientFormSchema) => void;
    onCloseForm: () => void;
  };
}) {
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");

  const currentPatient = selectedPatient ? apiToForm(selectedPatient) : undefined;

  type USState = z.infer<typeof usStateSchema>;

  const defaultAddress: Address = {
    addressLine1: "",
    addressLine2: undefined,
    city: "",
    state: "" as USState,
    zip: "",
    country: DEFAULT_COUNTRY as "USA",
  };
  const defaultContact: ContactNonNull = {
    phone: undefined,
    email: undefined,
  };

  // ADD SEARCH FACILITY

  const patientAddress = currentPatient ? currentPatient.address : [defaultAddress];
  const patientContact =
    currentPatient && currentPatient.contact.length > 0 ? currentPatient.contact : [defaultContact];
  return (
    <DrawerForm<PatientFormSchema>
      title={`${currentPatient ? "Edit" : "Create"} Patient`}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={actions.onCreateOrUpdatePatient}
      onClose={actions.onCloseForm}
      resolver={zodResolver(patientFormSchema)}
      defaultValues={{
        facilityId: currentPatient?.facilityId,
        firstName: currentPatient?.firstName,
        lastName: currentPatient?.lastName,
        dob: currentPatient?.dob,
        genderAtBirth: currentPatient?.genderAtBirth,
        driversLicense: {
          value: currentPatient?.driversLicense.value,
          state: currentPatient?.driversLicense.state,
        },
        externalId: currentPatient?.externalId,
        address: patientAddress,
        contact: patientContact,
      }}
    >
      {({ register, control, formState: { errors } }) => {
        const {
          fields: addresses,
          append: appendAddress,
          remove: removeAddress,
        } = useFieldArray({
          control,
          name: "address",
        });
        const {
          fields: contacts,
          append: appendContact,
          remove: removeContact,
        } = useFieldArray({
          control,
          name: "contact",
        });
        return (
          <>
            <Heading as="h4" size="sm">
              Choose Facility
            </Heading>
            <Select
              {...register("facilityId")}
              isRequired
              label="Facility *"
              placeholder="Select facility"
              options={facilities?.map(f => ({ label: f.name, value: f.id })) ?? []}
              error={get(errors, "facility")}
              defaultValue={facilities?.length === 1 ? facilities[0]?.id : undefined}
              isDisabled={!!currentPatient}
            />

            <Heading pt={7} as="h4" size="sm">
              Basic Info
            </Heading>
            <Input
              {...register("firstName")}
              isRequired
              label="First Name *"
              error={get(errors, "firstName[0]")}
            />
            <Input
              {...register("lastName")}
              isRequired
              label="Last Name *"
              error={get(errors, "lastName[0]")}
            />
            <Input
              {...register("dob")}
              type="date"
              isRequired
              label="Date of Birth *"
              error={get(errors, "dob")}
            />
            <Select
              {...register("genderAtBirth")}
              isRequired
              label="Gender at Birth *"
              placeholder="Select gender"
              options={mapToOptions(Constants.gendersAtBirth)}
              error={get(errors, "genderAtBirth")}
            />

            <Label>Driver's License</Label>
            <Flex w={"100%"} direction={isLargerThan1000 ? "row" : "column"}>
              <Input
                {...register("driversLicense.value")}
                error={get(errors, "driversLicense.value")}
              />
              <Spacer padding={2} flex={0} />
              <Select
                {...register("driversLicense.state")}
                options={Constants.usStatesForDriverLicense}
                placeholder="Select State"
                error={get(errors, "driversLicense.state")}
              />
            </Flex>

            <Input
              {...register("externalId")}
              label="External ID"
              error={get(errors, "externalId")}
            />
            {addresses.map((address, index) => {
              return (
                <>
                  <Heading as="h4" size="sm" pt={50}>
                    {`Address${index > 0 ? " " + (index + 1) : ""}`}
                  </Heading>
                  <Input
                    {...register(`address.${index}.addressLine1`)}
                    isRequired
                    label="Address Line 1 *"
                    error={get(errors, `address.${index}.addressLine1`)}
                    key={address.id}
                  />
                  <Input
                    {...register(`address.${index}.addressLine2`)}
                    label="Address Line 2"
                    error={get(errors, `address.${index}.addressLine2`)}
                    key={address.id}
                  />
                  <Input
                    {...register(`address.${index}.city`)}
                    isRequired
                    label="City *"
                    error={get(errors, `address.${index}.city`)}
                    key={address.id}
                  />
                  <Select
                    {...register(`address.${index}.state`)}
                    isRequired
                    label="State *"
                    name={`address.${index}.state`}
                    options={Constants.usStatesForAddress}
                    placeholder="Select State"
                    error={get(errors, `address.${index}.state`)}
                    key={address.id}
                  />
                  <Input
                    {...register(`address.${index}.zip`)}
                    isRequired
                    label="Zip *"
                    error={get(errors, `address.${index}.zip`)}
                    key={address.id}
                  />
                  <Input
                    {...register(`address.${index}.country`)}
                    label="Country"
                    error={get(errors, `address.${index}.country`)}
                    key={address.id}
                    disabled={true}
                  />
                  {index > 0 && (
                    <div>
                      <Button mt={5} onClick={() => removeAddress(index)}>{`Remove Address ${
                        index + 1
                      }`}</Button>
                    </div>
                  )}
                </>
              );
            })}
            <Button mt={15} onClick={() => appendAddress(defaultAddress)}>
              Add New Address
            </Button>
            {contacts.map((contact, index) => {
              return (
                <>
                  <Heading as="h4" size="sm" pt={50}>
                    {`Contact${index > 0 ? " " + (index + 1) : ""}`}
                  </Heading>
                  <Label>Phone</Label>
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <Input
                      type="number"
                      placeholder="4155404451"
                      {...register(`contact.${index}.phone`)}
                      error={get(errors, `contact.${index}.phone`)}
                      key={contact.id}
                    />
                  </InputGroup>
                  <Input
                    label="Email"
                    {...register(`contact.${index}.email`)}
                    error={get(errors, `contact.${index}.email`)}
                    key={contact.id}
                  />
                  {index > 0 && (
                    <div>
                      <Button mt={5} onClick={() => removeContact(index)}>{`Remove Contact ${
                        index + 1
                      }`}</Button>
                    </div>
                  )}
                </>
              );
            })}
            <Button mt={15} onClick={() => appendContact(defaultContact)}>
              Add New Contact
            </Button>
            {!currentPatient ? (
              <Box>
                <Heading as="h4" size="sm" mb={5} pt={50}>
                  Preview Potential Matches (coming soon)
                </Heading>
                <Button
                  // TODO: implement onClick
                  onClick={() => {
                    return;
                  }}
                  isActive={false}
                  isDisabled={true}
                  leftIcon={<Search2Icon />}
                >
                  Search for Potential Matches
                </Button>
              </Box>
            ) : null}
          </>
        );
      }}
    </DrawerForm>
  );
}

const apiToForm = (patient: PatientDTO): PatientFormSchema => {
  const driversLicense = patient.personalIdentifiers?.find(
    (id): id is DriverLicenseIdentifier => id.type === DRIVERS_LICENSE
  );
  return {
    ...patient,
    externalId: patient.externalId ?? undefined,
    facilityId: patient.facilityIds[0] ?? "",
    contact: getContact(patient),
    driversLicense: driversLicense
      ? {
          state: driversLicense.state,
          value: driversLicense.value,
        }
      : {
          state: "",
          value: "",
        },
    address: getAddress(patient) as [Address, ...Address[]], // forcing to Address b/c there are required fields that are not set on the backend
  };
};

function getContact(patient: PatientDTO): ContactNonNull[] {
  if (patient.contact) {
    const contact = Array.isArray(patient.contact) ? patient.contact : [patient.contact];
    return contact.map(c => {
      return {
        phone: c.phone ?? undefined,
        email: c.email ?? undefined,
      };
    });
  }
  return [];
}

function getAddress(
  patient: PatientDTO
): (Omit<Address, "state"> & Partial<Pick<Address, "state">>)[] {
  const address = Array.isArray(patient.address) ? patient.address : [patient.address];
  return address.map(address => {
    const state = address.state;
    const parsedState = usStateForAddressSchema.safeParse(state);

    return {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      state: parsedState.success ? parsedState.data : undefined,
      zip: address.zip,
      country: DEFAULT_COUNTRY,
    };
  });
}
