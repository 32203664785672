import { FamilyMemberHistory } from "@medplum/fhirtypes";
import { ColDef } from "ag-grid-community";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, getFirstCodeSpecified, getResourcesFromBundle, getValidCode } from "../shared";

export type FamilyMemberHistoryRowData = {
  id: string;
  familyMember: string;
  sex: string;
  conditions: string;
  deceased: string;
};

export const familyMemberHistoryTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<FamilyMemberHistoryRowData>[] = [
    { field: "id", hide: true },
    { field: "familyMember" },
    { field: "sex" },
    { field: "conditions" },
    { field: "deceased" },
  ];

  const familyMemberHistories = getResourcesFromBundle<FamilyMemberHistory>(
    bundle,
    "FamilyMemberHistory"
  );

  return {
    columnDefs,
    rowData: getFamilyMemberHistoryRowData({ familyMemberHistories, tableFilters }),
  };
};

function getFamilyMemberHistoryRowData({
  familyMemberHistories,
  tableFilters,
}: {
  familyMemberHistories: FamilyMemberHistory[];
  tableFilters: MrFilterSetting | undefined;
}): FamilyMemberHistoryRowData[] {
  return familyMemberHistories
    ?.map(familyMemberHistory => ({
      id: familyMemberHistory.id ?? "-",
      familyMember: getFamilyMemberDisplay(familyMemberHistory),
      sex: renderAdministrativeGender(familyMemberHistory),
      conditions: renderFamilyHistoryFamilyMemberHistorys(familyMemberHistory),
      deceased: renderFamilyMemberDeceased(familyMemberHistory),
    }))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

function getFamilyMemberDisplay(familyMemberHistory: FamilyMemberHistory): string {
  const codings = getValidCode(familyMemberHistory.relationship?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (familyMemberHistory.relationship?.text) {
    return familyMemberHistory.relationship.text;
  }

  return "-";
}

function renderAdministrativeGender(familyMemberHistory: FamilyMemberHistory): string {
  const adminGenCode = getFirstCodeSpecified(familyMemberHistory.sex?.coding, [
    "administrativegender",
  ]);

  if (adminGenCode?.code) {
    return adminGenCode.code;
  }

  return "-";
}

function renderFamilyHistoryFamilyMemberHistorys(familyMemberHistory: FamilyMemberHistory): string {
  const conditions = familyMemberHistory.condition?.map(condition => {
    return condition.code?.text ?? getValidCode(condition.code?.coding)[0]?.display;
  });

  return conditions?.join(", ") ?? "-";
}

function asYesNo(value: boolean): "yes" | "no" {
  return value ? ("yes" as const) : ("no" as const);
}

export const renderFamilyMemberDeceased = (
  familyMemberHistory: FamilyMemberHistory
): "yes" | "no" | "" => {
  const deceasedBoolean = familyMemberHistory.deceasedBoolean;
  if (deceasedBoolean !== undefined) {
    return asYesNo(deceasedBoolean);
  }

  const conditionContributedToDeath = familyMemberHistory.condition?.find(
    condition => condition.contributedToDeath
  );

  if (conditionContributedToDeath?.contributedToDeath !== undefined) {
    return asYesNo(conditionContributedToDeath.contributedToDeath);
  }

  return "";
};
