import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { PatientTab } from "../shared-logic/consolidated-context/reducer";
import { getRefAndUpdateHeight } from "../shared-logic/elementHeight";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { Button as MetriportButton } from "./button";
import { IdContainer } from "./id-copy-container";

type HeaderActions = {
  onQueryPatientDocuments: () => Promise<void>;
  onGenerateMedicalRecord: () => void;
  onEditPatient: () => void;
  onDisplayPatientDemos: () => void;
  onViewPatientMatches: () => void;
  onSetPatientTab: (tab: PatientTab) => void;
  toggleHieOptOut: (hieOptOut: boolean) => Promise<void>;
};

type HeaderParams = {
  patient: PatientDTO | undefined;
  isFetchingPatient: boolean;
  isQueryingDocuments: boolean;
  isAtLeastOneMrDownloading: boolean;
  isHieOptedOut: boolean;
  selectedTab: PatientTab;
  lastDocQuery: string | undefined;
  isLimited?: boolean;
  actions: HeaderActions;
  setHeaderHeight: (height: number) => void;
};

export function PatientHeader({
  patient,
  isFetchingPatient,
  isQueryingDocuments,
  isAtLeastOneMrDownloading,
  isHieOptedOut,
  selectedTab,
  lastDocQuery,
  isLimited,
  actions,
  setHeaderHeight,
}: HeaderParams) {
  const { elementRef, updateHeight } = getRefAndUpdateHeight(setHeaderHeight);
  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return (
    <Box ref={elementRef} bg={useColorModeValue("gray.100", "gray.900")}>
      {!isLimited && lastDocQuery && <LastQueried lastDocQuery={lastDocQuery} />}
      <Box
        bg={useColorModeValue("white", "gray.800")}
        border={"solid"}
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        px={5}
        py={isLimited ? 2 : 5}
      >
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Flex
            flexDirection={{ base: "column", md: isLimited ? "row" : "column" }}
            justifyContent={"flex-start"}
          >
            <Flex mt={1}>
              <PatientInfo
                isLimited={isLimited}
                patient={patient}
                isLoading={isFetchingPatient}
                actions={isLimited ? undefined : actions}
              />
            </Flex>
            <Flex mt={{ base: 4, md: isLimited ? 1 : 4 }} ml={{ base: 0, md: isLimited ? 5 : 0 }}>
              <PatientDemo
                isLimited={isLimited}
                patient={patient}
                isLoading={isFetchingPatient}
                actions={actions}
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection={{ base: "column", md: isLimited ? "row" : "column", xl: "row" }}
            justifyContent={"flex-end"}
          >
            <Flex mt={1}>
              {isLimited ? (
                <PatientTabs isLimited={isLimited} selectedTab={selectedTab} actions={actions} />
              ) : (
                <PatientButtonActions
                  isAtLeastOneMrDownloading={isAtLeastOneMrDownloading}
                  isQueryingDocuments={isQueryingDocuments}
                  actions={actions}
                />
              )}
            </Flex>
            <Flex
              mt={{ base: 2, md: isLimited ? 1 : 2, xl: 1 }}
              ml={{ base: "auto", md: isLimited ? 5 : "auto", xl: 5 }}
            >
              {isLimited ? (
                <PatientMenuActionsLimited actions={actions} />
              ) : (
                <PatientMenuActionsFull isHieOptedOut={isHieOptedOut} actions={actions} />
              )}
            </Flex>
          </Flex>
        </Flex>
        {!isLimited && (
          <>
            <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={5} />
            <PatientTabs selectedTab={selectedTab} actions={actions} />
          </>
        )}
      </Box>
    </Box>
  );
}

function LastQueried({ lastDocQuery }: { lastDocQuery: string | undefined }) {
  return (
    <Box
      display={{ base: "none", "2xl": "block" }}
      position={"absolute"}
      top={0}
      marginLeft={"auto"}
      marginRight={"auto"}
      left={0}
      right={0}
      border={"solid"}
      borderTop={0}
      borderBottomRadius={10}
      borderColor={METRIPORT_PRIMARY}
      width={"fit-content"}
      paddingX={4}
      paddingY={2}
      fontSize={"sm"}
    >
      <Text color={METRIPORT_PRIMARY}>Last Queried: {lastDocQuery ?? "-"}</Text>
    </Box>
  );
}

function PatientInfo({
  isLimited = false,
  patient,
  isLoading,
  actions,
}: {
  isLimited?: boolean;
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions?: Pick<HeaderActions, "onEditPatient">;
}) {
  const patientName = `${patient?.firstName} ${patient?.lastName}`;
  return (
    <Skeleton display={"flex"} flexDirection={"row"} isLoaded={!isLoading}>
      <Text fontSize={isLimited ? "xs" : "2xl"} fontWeight={"bold"}>
        {patientName}
      </Text>
      {actions && (
        <IconButton
          onClick={actions.onEditPatient}
          bg={"transparent"}
          _hover={{ color: "#879ced" }}
          aria-label="Edit Patient"
          icon={<EditIcon />}
        />
      )}
    </Skeleton>
  );
}

function PatientDemo({
  isLimited = false,
  patient,
  isLoading,
  actions,
}: {
  isLimited?: boolean;
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions: Pick<HeaderActions, "onDisplayPatientDemos">;
}) {
  const demoFields: Array<{ label: string; value: string | undefined }> = [
    { label: "id", value: patient?.id },
    { label: "dob", value: patient?.dob },
    { label: "gender", value: patient?.genderAtBirth },
  ];
  return (
    <Skeleton display={"flex"} flexDirection={"row"} isLoaded={!isLoading}>
      {demoFields.map(({ label, value }, i) => (
        <Flex
          key={i}
          pl={i === 0 ? 0 : 2}
          pr={2}
          borderRight={"solid"}
          borderColor={useColorModeValue("gray.300", "gray.600")}
          whiteSpace={"nowrap"}
        >
          <Box>
            {label === "id" ? (
              <IdContainer fontSize={isLimited ? "xs" : "md"} value={value} entity={"Patient"} />
            ) : (
              <Text fontSize={isLimited ? "xs" : "md"}>{value}</Text>
            )}
          </Box>
        </Flex>
      ))}
      <Icon
        pl={2}
        onClick={actions.onDisplayPatientDemos}
        bg={"transparent"}
        _hover={{ color: "#879ced" }}
        cursor={"pointer"}
        aria-label="Patient Demographics"
        as={FiInfo}
        fontSize={isLimited ? "xl" : "2xl"}
      />
    </Skeleton>
  );
}

function PatientTabs({
  isLimited = false,
  selectedTab,
  actions,
}: {
  isLimited?: boolean;
  selectedTab: PatientTab;
  actions: Pick<HeaderActions, "onSetPatientTab">;
}) {
  return (
    <Flex justifyContent={"center"}>
      <MetriportButton
        mr={5}
        size={isLimited ? "xs" : "md"}
        onClick={() => actions.onSetPatientTab("fhir")}
        variant={selectedTab === "fhir" ? "solid" : "outline"}
      >
        View Summary
      </MetriportButton>
      <MetriportButton
        size={isLimited ? "xs" : "md"}
        onClick={() => actions.onSetPatientTab("documents")}
        variant={selectedTab === "documents" ? "solid" : "outline"}
      >
        View Raw Documents
      </MetriportButton>
    </Flex>
  );
}

function PatientButtonActions({
  isAtLeastOneMrDownloading,
  isQueryingDocuments,
  actions,
}: {
  isAtLeastOneMrDownloading: boolean;
  isQueryingDocuments: boolean;
  actions: Pick<HeaderActions, "onGenerateMedicalRecord" | "onQueryPatientDocuments">;
}) {
  return (
    <Flex justifyContent={"center"}>
      <MetriportButton
        mr={5}
        size={"md"}
        isLoading={isAtLeastOneMrDownloading}
        onClick={actions.onGenerateMedicalRecord}
      >
        Generate Medical Record
      </MetriportButton>
      <MetriportButton
        size={"md"}
        isLoading={isQueryingDocuments}
        onClick={actions.onQueryPatientDocuments}
        variant={"outline"}
      >
        Request Patient Data
      </MetriportButton>
    </Flex>
  );
}

function PatientMenuActionsFull({
  isHieOptedOut,
  actions,
}: {
  isHieOptedOut?: boolean;
  actions: Pick<HeaderActions, "onViewPatientMatches" | "onSetPatientTab" | "toggleHieOptOut">;
}) {
  const bg = useColorModeValue("gray.50", "gray.800");
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList bg={bg}>
        <MenuItem
          onClick={() => {
            actions.onSetPatientTab("matches");
            actions.onViewPatientMatches();
          }}
          bg={bg}
        >
          View Patient Matches
        </MenuItem>
        <MenuItem onClick={() => actions.toggleHieOptOut(!isHieOptedOut)} bg={bg}>
          {isHieOptedOut ? "Opt In to" : "Opt Out"} of Networks
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function PatientMenuActionsLimited({
  actions,
}: {
  actions: Pick<HeaderActions, "onGenerateMedicalRecord" | "onQueryPatientDocuments">;
}) {
  const bg = useColorModeValue("gray.50", "gray.800");
  return (
    <Menu placement="bottom-end">
      <MenuButton
        size={"xs"}
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList bg={bg}>
        <MenuItem onClick={actions.onQueryPatientDocuments} bg={bg}>
          Request Patient Data
        </MenuItem>
        <MenuItem onClick={actions.onGenerateMedicalRecord} bg={bg}>
          Generate Medical Record
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
