import { Binary } from "@medplum/fhirtypes";
import { Box, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";

export function SummaryContent({
  mappedConsolidated,
  isLimited = false,
}: {
  mappedConsolidated?: MappedConsolidatedResources | undefined;
  isLimited?: boolean;
}) {
  const aiSummary = getAiSummaryFromMappedConsolidated(mappedConsolidated);

  if (!aiSummary) {
    return null;
  }

  return (
    <Box
      id="ai-summary"
      w={"100%"}
      mb={5}
      borderRadius={10}
      bg={useColorModeValue("white", "gray.800")}
      px={7}
      py={5}
      border="solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Box display={"flex"} alignItems={"center"} mb={3}>
        <Heading size={isLimited ? "xs" : "md"}>AI Summary</Heading>
      </Box>
      <Text>{aiSummary}</Text>
    </Box>
  );
}

export function getAiSummaryFromMappedConsolidated(
  mappedConsolidated: MappedConsolidatedResources | undefined
): string | undefined {
  const binaryResourceMap = mappedConsolidated?.["Binary"];

  if (!binaryResourceMap) {
    return undefined;
  }

  const binaryResource = Object.values(binaryResourceMap)[0] as Binary | undefined;

  if (!binaryResource) {
    return undefined;
  }

  const b64String = binaryResource.data;

  if (!b64String) {
    return undefined;
  }

  const decodedString = atob(b64String);

  return decodedString;
}
