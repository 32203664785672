import { Encounter, Location } from "@medplum/fhirtypes";
import { limitStringLength, toTitleCase } from "@metriport/shared";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate, getResourcesFromBundle, getValidCode } from "../shared";
export type EncounterRowData = {
  id: string;
  reasons: string;
  location: string;
  type: string;
  startDate: string;
  endDate: string;
};

const MAX_NUM_DISPLAY_CHARS = 50;

export const encounterTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<EncounterRowData>[] = [
    { field: "id", hide: true },
    {
      field: "reasons",
      cellRenderer: (params: { value: string }) =>
        limitStringLength(params.value ?? "-", MAX_NUM_DISPLAY_CHARS),
    },
    {
      field: "type",
      headerName: "Type / Class",
      cellRenderer: (params: { value: string }) =>
        limitStringLength(params.value ?? "-", MAX_NUM_DISPLAY_CHARS),
    },
    { field: "location" },
    { field: "startDate", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "endDate" },
  ];

  const encounters = getResourcesFromBundle<Encounter>(bundle, "Encounter");
  const locations = getResourcesFromBundle<Location>(bundle, "Location");

  return {
    columnDefs,
    rowData: getEncounterRowData({ encounters, locations, tableFilters }),
  };
};

function getEncounterRowData({
  encounters,
  locations,
  tableFilters,
}: {
  encounters: Encounter[];
  locations: Location[];
  tableFilters: MrFilterSetting | undefined;
}): EncounterRowData[] {
  return encounters
    ?.map(encounter => ({
      id: encounter.id ?? "-",
      reasons: getEncountersDisplay(encounter),
      location: getEncounterLocation(encounter, locations),
      type: renderClassDisplay(encounter),
      startDate: encounter.period?.start ? dayjs(encounter.period.start).format(ISO_DATE) : "-",
      endDate: encounter.period?.end ? dayjs(encounter.period.end).format(ISO_DATE) : "-",
    }))
    .filter(row => filterByDate(row.startDate, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

function getEncountersDisplay(encounter: Encounter): string {
  const reasonSet = new Set<string>();

  for (const reason of encounter.reasonCode ?? []) {
    const text = reason.text;

    if (text) {
      reasonSet.add(normalizeDisplay(text));
    }

    const codings = getValidCode(reason.coding);

    codings.forEach(c => {
      if (c.display) reasonSet.add(normalizeDisplay(c.display));
    });
  }

  const reasons = Array.from(reasonSet);
  return reasons.length > 0 ? reasons.join(", ") : "-";
}

function getEncounterLocation(encounter: Encounter, locations: Location[]): string {
  const locationId = encounter.location?.[0]?.location?.reference?.split("/")?.[1];
  const location = locations.find(l => l.id === locationId);
  return location?.name ?? "-";
}

function renderClassDisplay(encounter: Encounter): string {
  const isUsefulDisplay = isDisplayUseful(encounter.class?.display);

  if (encounter.class?.display && isUsefulDisplay) {
    return normalizeDisplay(encounter.class?.display);
  } else if (encounter.class?.extension) {
    const extension = encounter.class?.extension?.find(coding => {
      return coding.valueCoding?.code === encounter.class?.code;
    });
    return extension?.valueCoding?.display ? normalizeDisplay(extension.valueCoding.display) : "-";
  } else if (encounter.type) {
    const allTypeStringSet = new Set<string>();

    for (const type of encounter.type) {
      if (type.text && isDisplayUseful(type.text)) {
        allTypeStringSet.add(normalizeDisplay(type.text));
      }
      type.coding?.forEach(c => {
        if (c.display) allTypeStringSet.add(c.display);
      });
    }
    return Array.from(allTypeStringSet).join(", ");
  }

  return "-";
}

function isDisplayUseful(display: string | undefined) {
  return display !== undefined && display !== "unknown";
}

function normalizeDisplay(str: string): string {
  return toTitleCase(str.trim());
}
