import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import { useEffect, useState } from "react";
import { MrFilterSetting } from "../../../../api/settings";
import { EhrDetails } from "../../../../domain/ehr";
import {
  MappedConsolidatedResources,
  PatientQueryParamProps,
} from "../../shared-logic/consolidated-context/reducer";
import { useSharedContext } from "../../shared-logic/shared-context";
import useMetriportToast from "../../shared-logic/useMetriportToast";
import { NoPatientData } from "../no-data";
import { SidePanel } from "../side-panel/side-panel";
import { FhirSection, FhirSectionLegacyVersion, ToggleProps } from "./fhir-section";
import { DetailedReportRowData, LabRowData } from "./labs/shared";

export type SectionCounts = {
  sectionCounts: {
    reports: number;
    documents: number;
  };
};

export type EhrActions = EhrDetails & {
  writingIds: string[];
  failedIds: string[];
  successIds: string[];
  setWritingIds: React.Dispatch<React.SetStateAction<string[]>>;
  setFailedIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSuccessIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export type GenerateTableDataParams = {
  bundle: MappedConsolidatedResources | undefined;
  tableFilters: MrFilterSetting | undefined;
  ehrActions?: EhrActions;
};

export type GenerateTableData = ({ bundle, tableFilters, ehrActions }: GenerateTableDataParams) => {
  columnDefs: ColDef[];
  rowData: object[];
  alternativeRowData?: object[];
};

type GenerateComplexTableDataParams = {
  bundle: MappedConsolidatedResources | undefined;
  tableFilters: MrFilterSetting | undefined;
  ehrActions?: EhrActions;
};

type GenerateComplexTableData = ({
  bundle,
  tableFilters,
  ehrActions,
}: GenerateComplexTableDataParams) => {
  mainColumnDefs: ColDef[];
  mainRowData: DetailedReportRowData[];
  secondaryColumnDefs: ColDef[];
  secondaryRowData: Map<string, LabRowData[]>;
  groupedRowData: LabRowData[];
};

export type FhirSections = {
  title: string;
  key: string;
  generateTableData?: GenerateTableData;
  generateMultipleTablesData?: GenerateComplexTableData;
  sidePanelContent: React.ElementType;
  toggleProps?: ToggleProps;
}[];

export function PatientFHIRData({
  fhirSections,
  filters,
  mappedConsolidated,
  isConsolidatedLoading,
  patientQueryParams,
  isImpersonating,
  isLimited = false,
}: {
  fhirSections: FhirSections;
  filters: MrFilterSetting[] | undefined;
  mappedConsolidated: MappedConsolidatedResources | undefined;
  isConsolidatedLoading: boolean;
  patientQueryParams?: PatientQueryParamProps | undefined;
  isImpersonating?: boolean;
  isLimited?: boolean;
}) {
  const toast = useMetriportToast();
  const { sharedState } = useSharedContext();
  const [writingToEhrIds, setWritingToEhrIds] = useState<string[]>([]);
  const [failedToEhrIds, setFailedToEhrIds] = useState<string[]>([]);
  const [successToEhrIds, setSuccessToEhrIds] = useState<string[]>([]);
  const [sidePanelContent, setSidePanelContent] = useState<React.ReactNode | undefined>(undefined);

  useEffect(() => {
    if (successToEhrIds.length > 0 && ehrActions) {
      toast.success({ title: `Successfully added to ${ehrActions.name}` });
    }
  }, [successToEhrIds]);

  useEffect(() => {
    if (failedToEhrIds.length > 0 && ehrActions) {
      toast.error({ title: `Error adding to ${ehrActions.name}` });
    }
  }, [failedToEhrIds]);

  if (
    mappedConsolidated &&
    Object.keys(mappedConsolidated).length === 0 &&
    !isConsolidatedLoading
  ) {
    return (
      <Box>
        <NoPatientData />
      </Box>
    );
  }

  const ehrActions = sharedState.ehrDetails
    ? {
        ...sharedState.ehrDetails,
        writingIds: writingToEhrIds,
        failedIds: failedToEhrIds,
        successIds: successToEhrIds,
        setWritingIds: setWritingToEhrIds,
        setFailedIds: setFailedToEhrIds,
        setSuccessIds: setSuccessToEhrIds,
      }
    : undefined;

  return (
    <Box>
      <Flex>
        <Skeleton
          flex={1}
          borderRadius={10}
          isLoaded={mappedConsolidated !== undefined && !isConsolidatedLoading}
        >
          <Sections
            isImpersonating={isImpersonating}
            fhirSections={fhirSections}
            filters={filters}
            mappedConsolidated={mappedConsolidated}
            sidePanelContent={sidePanelContent}
            actions={{
              setSidePanelContent: (content: React.ReactNode) => {
                setSidePanelContent(content);
                const sidePanelContentId = document.getElementById("side-panel");
                sidePanelContentId?.scrollTo({ top: 0 });
              },
            }}
            patientQueryParams={patientQueryParams}
            ehrActions={ehrActions}
            isLimited={isLimited}
          />
        </Skeleton>
        <SidePanel
          setSidePanelContent={() => {
            patientQueryParams?.clearPatientQueryParams();
            setSidePanelContent(undefined);
          }}
          showPanel={!!sidePanelContent}
        >
          {sidePanelContent}
        </SidePanel>
      </Flex>
    </Box>
  );
}

function Sections({
  fhirSections,
  filters,
  mappedConsolidated,
  actions,
  patientQueryParams,
  sidePanelContent,
  ehrActions,
  isImpersonating,
  isLimited = false,
}: {
  fhirSections: FhirSections;
  filters: MrFilterSetting[] | undefined;
  mappedConsolidated: MappedConsolidatedResources | undefined;
  actions: {
    setSidePanelContent: (content: React.ReactNode) => void;
  };
  patientQueryParams?: PatientQueryParamProps | undefined;
  sidePanelContent: React.ReactNode | undefined;
  ehrActions: EhrActions | undefined;
  isImpersonating?: boolean;
  isLimited?: boolean;
}) {
  let sections = fhirSections;
  if (filters) {
    const filteredSections = [];

    for (const filter of filters) {
      const { key } = filter;

      const section = sections.find(section => section.key === key);

      if (section) {
        filteredSections.push(section);
      }
    }

    sections = filteredSections;
  }
  const sectionNameParam = patientQueryParams?.sectionName;
  const resourceIdParam = patientQueryParams?.resourceId;

  return (
    <>
      {sections.map(section => {
        const sectionFilter = filters?.find(filter => filter.key === section.key);

        const genMultipleData = section.generateMultipleTablesData;
        if (genMultipleData && section.toggleProps) {
          return handleComplexFhirSections({
            section: {
              ...section,
              generateMultipleTablesData: genMultipleData,
            },
            filters,
            mappedConsolidated,
            actions,
            patientQueryParams,
            sidePanelContent,
            ehrActions,
            isImpersonating,
            toggleProps: section.toggleProps,
          });
        }

        if (!section.generateTableData) return;

        const { columnDefs, rowData, alternativeRowData } = section.generateTableData({
          bundle: mappedConsolidated,
          tableFilters: sectionFilter,
          ehrActions,
        });

        if (sectionNameParam === section.title.toLowerCase() && resourceIdParam) {
          // TODO: Define rowContents type and remove the check for the presence of "id"
          const rowContents = rowData.find(row => "id" in row && row.id === resourceIdParam);

          const sidePanelContentId =
            sidePanelContent && typeof sidePanelContent === "object" && "props" in sidePanelContent
              ? sidePanelContent.props.id
              : undefined;

          if (rowContents && (!sidePanelContent || resourceIdParam !== sidePanelContentId)) {
            const Component = section.sidePanelContent;
            actions.setSidePanelContent(
              <Component
                isImpersonating={isImpersonating}
                mappedConsolidated={mappedConsolidated}
                {...rowContents}
              />
            );
          }
        }

        return (
          <FhirSection
            key={section.key}
            sectionId={section.key}
            sectionName={section.title}
            filters={sectionFilter}
            columnDefs={columnDefs}
            rowData={rowData}
            alternativeRowData={alternativeRowData}
            actions={{
              onRowCellClicked: (rowContents: object) => {
                // TODO: Define rowContents type and remove this check
                if ("id" in rowContents) {
                  const newSectionParam = section.title.toLowerCase();
                  const newIdParam = rowContents.id as string;
                  if (!(newSectionParam === sectionNameParam && newIdParam === resourceIdParam)) {
                    patientQueryParams?.setPatientQueryParams({
                      sectionName: newSectionParam,
                      resourceId: newIdParam,
                    });
                  }
                }

                const Component = section.sidePanelContent;
                actions.setSidePanelContent(
                  <Component
                    isImpersonating={isImpersonating}
                    mappedConsolidated={mappedConsolidated}
                    {...rowContents}
                  />
                );
              },
            }}
            toggleProps={section.toggleProps}
            isLimited={isLimited}
          />
        );
      })}
    </>
  );
}

export function sectionsHaveData({ sectionCounts }: SectionCounts): boolean {
  return Object.values(sectionCounts).some(count => count > 0);
}

function isDetailedReportRowData(
  rows: DetailedReportRowData[] | LabRowData[]
): rows is DetailedReportRowData[] {
  return (
    Array.isArray(rows) &&
    rows.length > 0 &&
    rows[0] != undefined &&
    rows.some(row => "results" in row)
  );
}

function isLabRowData(rows: DetailedReportRowData[] | LabRowData[]): rows is LabRowData[] {
  return (
    Array.isArray(rows) &&
    rows.length > 0 &&
    rows[0] != undefined &&
    rows.some(row => "observation" in row)
  );
}

function handleComplexFhirSections({
  section,
  filters,
  mappedConsolidated,
  actions,
  patientQueryParams,
  sidePanelContent,
  ehrActions,
  isImpersonating,
  toggleProps,
  isLimited = false,
}: {
  section: Omit<FhirSections[number], "generateMultipleTablesData"> & {
    generateMultipleTablesData: GenerateComplexTableData;
  };
  filters: MrFilterSetting[] | undefined;
  mappedConsolidated: MappedConsolidatedResources | undefined;
  actions: {
    setSidePanelContent: (content: React.ReactNode) => void;
  };
  patientQueryParams?: PatientQueryParamProps | undefined;
  sidePanelContent: React.ReactNode | undefined;
  ehrActions: EhrActions | undefined;
  isImpersonating?: boolean;
  toggleProps: ToggleProps;
  isLimited?: boolean;
}) {
  const [viewMode, setViewMode] = useState<string>(toggleProps.default);
  const [selectedGrouping, setSelectedGrouping] = useState<DetailedReportRowData | undefined>(
    undefined
  );
  const sectionFilter = filters?.find(filter => filter.key === section.key);
  const { mainColumnDefs, mainRowData, secondaryColumnDefs, secondaryRowData, groupedRowData } =
    section.generateMultipleTablesData({
      bundle: mappedConsolidated,
      tableFilters: sectionFilter,
      ehrActions,
    });

  const [rowData, setRowData] = useState<DetailedReportRowData[] | LabRowData[]>(mainRowData);
  const [colDefs, setColDefs] = useState(mainColumnDefs);

  const handleToggle = () => {
    setViewMode(prevMode => {
      const newMode =
        prevMode === toggleProps.alternative ? toggleProps.default : toggleProps.alternative;
      return newMode;
    });
  };
  const viewModeProps = { viewMode, setViewMode, handleToggle };

  const sectionNameParam = patientQueryParams?.sectionName;
  const resourceIdParam = patientQueryParams?.resourceId;

  if (sectionNameParam === section.title.toLowerCase() && resourceIdParam) {
    // TODO: Define rowContents type and remove the check for the presence of "id"
    const rowContents = groupedRowData.find(row => "id" in row && row.id === resourceIdParam);

    const sidePanelContentId =
      sidePanelContent && typeof sidePanelContent === "object" && "props" in sidePanelContent
        ? sidePanelContent.props.id
        : undefined;

    if (rowContents && (!sidePanelContent || resourceIdParam !== sidePanelContentId)) {
      const Component = section.sidePanelContent;
      actions.setSidePanelContent(
        <Component
          isImpersonating={isImpersonating}
          mappedConsolidated={mappedConsolidated}
          {...rowContents}
        />
      );
    }
  }

  if (viewMode === toggleProps.default) {
    return (
      <FhirSectionLegacyVersion
        key={section.key}
        sectionId={section.key}
        sectionName={section.title}
        filters={sectionFilter}
        columnDefs={secondaryColumnDefs}
        actions={{
          onRowCellClicked: (rowContents: object) => {
            // TODO: Define rowContents type and remove this check
            if ("id" in rowContents) {
              const newSectionParam = section.title.toLowerCase();
              const newIdParam = rowContents.id as string;
              if (!(newSectionParam === sectionNameParam && newIdParam === resourceIdParam)) {
                patientQueryParams?.setPatientQueryParams({
                  sectionName: newSectionParam,
                  resourceId: newIdParam,
                });
              }
            }

            const Component = section.sidePanelContent;
            actions.setSidePanelContent(
              <Component
                isImpersonating={isImpersonating}
                mappedConsolidated={mappedConsolidated}
                {...rowContents}
              />
            );
          },
        }}
        rowData={groupedRowData}
        toggleProps={toggleProps}
        viewModeProps={viewModeProps}
        isLimited={isLimited}
      />
    );
  }

  function clearSelectedGrouping(): void {
    setColDefs(mainColumnDefs);
    setRowData(mainRowData);
    setSelectedGrouping(undefined);
  }

  const expandedViewProps = {
    selectedGrouping,
    setSelectedGrouping,
    clearSelectedGrouping,
  };

  return (
    <FhirSectionLegacyVersion
      key={section.key}
      sectionId={section.key}
      sectionName={section.title}
      filters={sectionFilter}
      columnDefs={colDefs}
      rowData={rowData}
      actions={{
        onRowCellClicked: rowContents => {
          if (rowData.length > 0 && isDetailedReportRowData(rowData)) {
            const report = rowData.find(row => row === rowContents);
            const reportId = report?.id;
            if (!reportId) return;

            const newRowData = secondaryRowData.get(reportId);
            if (!newRowData) return;

            setColDefs(secondaryColumnDefs.filter(col => col.field !== "date"));
            setRowData(newRowData.sort((a, b) => a.observation.localeCompare(b.observation)));
            setSelectedGrouping(report);
            // TODO: Add navigation to the selected report?
          }

          if (rowData.length > 0 && isLabRowData(rowData)) {
            if ("id" in rowContents) {
              const newSectionParam = section.title.toLowerCase();
              const newIdParam = rowContents.id as string;
              if (!(newSectionParam === sectionNameParam && newIdParam === resourceIdParam)) {
                patientQueryParams?.setPatientQueryParams({
                  sectionName: newSectionParam,
                  resourceId: newIdParam,
                });
              }
            }

            const Component = section.sidePanelContent;
            actions.setSidePanelContent(
              <Component
                isImpersonating={isImpersonating}
                mappedConsolidated={mappedConsolidated}
                {...rowContents}
              />
            );
          }
        },
      }}
      toggleProps={toggleProps}
      viewModeProps={viewModeProps}
      expandedViewProps={expandedViewProps}
      isLimited={isLimited}
    />
  );
}
