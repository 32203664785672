import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { AgGridReact } from "@ag-grid-community/react";
import { EditIcon } from "@chakra-ui/icons";
import { Box, IconButton, useColorMode } from "@chakra-ui/react";
import { Facility, PatientDTO, ResponseMeta } from "@metriport/api-sdk";
import { getGridClassName, IdContainer } from "@metriport/shared-internal";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import dayjs from "dayjs";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

ModuleRegistry.registerModules([InfiniteRowModelModule]);

type AdditionalIds = PatientDTO["additionalIds"];

const NUMBER_OF_CHARS_TO_DISPLAY = 12;
const EXTERNAL_DEFAULT_VALUE = "-";

/** Maps row number to page URL */
type RowMapping = Record<string, string>;

type PatientRowData = {
  externalId: string;
  id: string;
  name: string;
  dob: string;
  gender: string;
  dateCreated: string;
  facility: string;
};

export type Props = {
  facilities: Facility[] | undefined;
  getPatients: (
    pageUrl?: string | undefined,
    filters?: string | undefined
  ) => Promise<{ patients: PatientDTO[]; meta: ResponseMeta } | undefined>;
  onPatientClick: (patientId: string) => void;
  isLoading: boolean;
  onPatientEdit: (patient: PatientDTO) => void;
  numItemsPerPage?: number | undefined;
};

export type PatientGridRef = {
  resetSearch: (filters: string) => void;
  updatePage: (filters: string) => void;
};

type GridState = {
  rowMapping: RowMapping;
  filters: string;
};
const initialState: GridState = { rowMapping: {}, filters: "" };

const PatientGrid = forwardRef<PatientGridRef, React.PropsWithChildren<Props>>(
  (
    {
      facilities,
      getPatients,
      onPatientClick,
      isLoading,
      onPatientEdit,
      numItemsPerPage = 50,
    }: Props,
    ref: ForwardedRef<PatientGridRef>
  ) => {
    const { colorMode } = useColorMode();
    const [patients, setPatients] = useState<PatientDTO[] | undefined>(undefined);
    const [api, setApi] = useState<GridApi | null>(null);
    const [gridState] = useState<GridState>(initialState);

    const columnDefs: ColDef[] = useMemo(() => {
      const atLeastOneExternalId = patientsHaveAtLeastOneExternalId(patients);
      const atLeastOneAdditionalId = patientsHaveAtLeastOneAdditionalId(patients);
      return [
        { field: "name", sortable: false },
        { field: "dob", headerName: "DOB", sortable: false, maxWidth: 150 },
        { field: "gender", sortable: false, maxWidth: 100 },
        { field: "dateCreated", sortable: false, maxWidth: 150 },
        { field: "facility", sortable: false },
        {
          field: "externalId",
          headerName: "External ID",
          sortable: false,
          hide: !atLeastOneExternalId,
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return (
              <IdContainer
                value={params.value}
                entity={"External"}
                full={false}
                partialMode={"left-to-right"}
                partialLength={NUMBER_OF_CHARS_TO_DISPLAY}
              />
            );
          },
          maxWidth: 350,
        },
        {
          field: "id",
          sortable: false,
          headerName: "ID",
          cellClass: "clickable-cell",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return (
              <IdContainer
                value={params.value}
                entity={"Patient"}
                full={false}
                partialMode={"right-to-left"}
                partialLength={NUMBER_OF_CHARS_TO_DISPLAY}
              />
            );
          },
          maxWidth: 350,
        },
        {
          field: "additionalIds",
          headerName: "Additional IDs",
          sortable: false,
          hide: !atLeastOneAdditionalId,
          onCellClicked: () => null,
          cellRenderer: (params: { value: AdditionalIds | undefined }) => {
            return createAdditionalIds(params.value);
          },
          maxWidth: 350,
        },
        {
          field: "actions",
          maxWidth: 150,
          sortable: false,
          onCellClicked: () => null,
          cellRenderer: (props: CustomCellRendererProps) => {
            if (!patients || !props.data) return null;
            const patient = patients.find(f => f.id === props.data.id);
            if (!patient) return null;
            return (
              <IconButton
                onClick={() => onPatientEdit(patient)}
                bg={"#748df0"}
                color={"white"}
                _hover={{ bg: "#879ced" }}
                aria-label="Edit Patient"
                icon={<EditIcon />}
              />
            );
          },
        },
      ];
    }, [patients]);

    const defaultColDef: ColDef = {
      flex: 1,
      onCellClicked: e => {
        onPatientClick(e.data.id);
      },
    };

    const getRows = useCallback(
      (api: GridApi) => async (params: IGetRowsParams) => {
        try {
          const { gridState: localGridState }: { gridState: GridState } = params.context;
          const currPageNumber = api.paginationGetCurrentPage();
          const currPageUrl = (localGridState.rowMapping ?? {})[currPageNumber];
          const filters = localGridState.filters;
          const resp = await getPatients(currPageUrl, filters);
          if (!resp) {
            params.failCallback();
            return;
          }
          const { patients, meta } = resp;
          if (!patients || !facilities) {
            params.failCallback();
            return;
          }
          setPatients(patients);
          const rowData = mapPatientsToRowData(patients, facilities);
          const totalAmountOfItems = getTotalAmountOfItems(meta, currPageNumber, numItemsPerPage);
          params.successCallback(rowData, totalAmountOfItems);
          updateRowMapping(meta, currPageNumber, localGridState);
        } catch (error) {
          params.failCallback();
        }
      },
      [getPatients, facilities, numItemsPerPage]
    );

    const onGridReady = useCallback(
      (params: GridReadyEvent) => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: getRows(params.api),
        };
        params.api.setGridOption("datasource", dataSource);
        setApi(params.api);
      },
      [getRows]
    );

    useImperativeHandle(ref, () => ({
      resetSearch(filters: string) {
        if (api) {
          gridState.filters = filters;
          gridState.rowMapping = {};
          api.paginationGoToPage(0);
          api.purgeInfiniteCache();
        }
      },
      updatePage(filters: string) {
        if (api) {
          gridState.filters = filters;
          api.purgeInfiniteCache();
        }
      },
    }));

    return facilities ? (
      <AgGridReact
        className={getGridClassName(colorMode)}
        loading={isLoading}
        headerHeight={60}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pagination={true}
        rowStyle={{ cursor: "pointer" }}
        suppressCellFocus={true}
        rowModelType={"infinite"}
        paginationPageSizeSelector={false}
        paginationPageSize={numItemsPerPage}
        cacheOverflowSize={1}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={0}
        maxBlocksInCache={1}
        cacheBlockSize={numItemsPerPage}
        onGridReady={onGridReady}
        context={{ gridState }}
      />
    ) : null;
  }
);

function mapPatientsToRowData(patients: PatientDTO[], facilities: Facility[]): PatientRowData[] {
  return patients?.map(patient => {
    return {
      externalId: parseExternalId(patient),
      additionalIds: patient.additionalIds,
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      dob: patient.dob,
      gender: patient.genderAtBirth,
      dateCreated: dayjs(patient.dateCreated).format("YYYY-MM-DD"),
      facility: getFacilityNames(facilities, patient),
    };
  });
}

function updateRowMapping(
  paginationMeta: ResponseMeta,
  currPageNumber: number,
  myState: GridState
): void {
  const nextPageNumber = currPageNumber + 1;
  const nextPageUrl = paginationMeta.nextPage;
  const rowMappingUpdate: RowMapping = {
    ...(nextPageNumber >= 0 && nextPageUrl ? { [nextPageNumber]: nextPageUrl } : {}),
  };
  if (currPageNumber === 1 && paginationMeta.prevPage) {
    rowMappingUpdate[0] = paginationMeta.prevPage;
  }
  const newRowMapping = { ...myState.rowMapping, ...rowMappingUpdate };
  myState.rowMapping = newRowMapping;
}

function getTotalAmountOfItems(
  paginationMeta: ResponseMeta,
  currPageNumber: number,
  numItemsPerPage: number
): number | undefined {
  if (!paginationMeta.nextPage) {
    const totalNumItems = paginationMeta.itemsOnPage + currPageNumber * numItemsPerPage;
    return totalNumItems;
  }
  return -1;
}

function patientsHaveAtLeastOneExternalId(patients: PatientDTO[] | undefined): boolean {
  return patients?.some(patient => parseExternalId(patient) !== EXTERNAL_DEFAULT_VALUE) ?? false;
}

function patientsHaveAtLeastOneAdditionalId(patients: PatientDTO[] | undefined): boolean {
  return patients?.some(patient => patient.additionalIds) ?? false;
}

function getFacilityNames(facilities: Facility[] | undefined, patient: PatientDTO): string {
  const facilityMap = new Map<string, string>();

  facilities?.forEach(facility => {
    facilityMap.set(facility.id, facility.name);
  });

  return patient.facilityIds.map(id => facilityMap.get(id)).join(", ");
}

function parseExternalId(patient: PatientDTO): string {
  const externalId = patient.externalId;
  if (!externalId) return EXTERNAL_DEFAULT_VALUE;
  const externalIdIsInAdditionalIds = Object.values(patient.additionalIds ?? {}).some(value =>
    value.some(id => id === externalId || externalId.endsWith(id))
  );
  if (externalIdIsInAdditionalIds) return EXTERNAL_DEFAULT_VALUE;
  return externalId;
}

function createAdditionalIds(additionalIds: AdditionalIds | undefined): JSX.Element {
  if (!additionalIds) return <></>;
  const parsedAdditionalIds: { key: string; ids: string[] }[] = Object.entries(additionalIds)
    .filter(([key]) => key !== "")
    .map(([key, value]) => ({
      key: key[0]?.toUpperCase() + key.slice(1),
      ids: value,
    }));
  return (
    <Box>
      {parsedAdditionalIds.map(({ key, ids }) => {
        return ids.map(value => (
          <Box mr={2}>
            <IdContainer
              value={value}
              prefix={key}
              entity={"External"}
              full={false}
              partialMode="left-to-right"
              partialLength={NUMBER_OF_CHARS_TO_DISPLAY}
            />
          </Box>
        ));
      })}
    </Box>
  );
}

export default PatientGrid;
