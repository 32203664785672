import { UseToastOptions } from "@chakra-ui/react";
import { AxiosError, isAxiosError } from "axios";
import { axiosErrorCodes, httpStatusCodes } from "../shared/constants";
import dayjs from "dayjs";

const TOAST_DURATION = dayjs.duration(10, "second").asMilliseconds();

type Toast = {
  error: (options: UseToastOptions) => void;
  isActive: (id: string) => boolean;
};

export const codes = [
  httpStatusCodes.BAD_REQUEST,
  httpStatusCodes.UNAUTHORIZED,
  httpStatusCodes.FORBIDDEN,
  httpStatusCodes.NOT_FOUND,
  httpStatusCodes.BAD_GATEWAY,
  httpStatusCodes.SERVICE_UNAVAILABLE,
  httpStatusCodes.GATEWAY_TIMEOUT,
  axiosErrorCodes.TIMEOUT,
  axiosErrorCodes.NETWORK_ERROR,
] as const;
type Code = (typeof codes)[number];

type CodeToActionMap = {
  [key in Code]: { path?: string; toast?: string };
};

type Handled = { handled: boolean };

const defaultErrorActionsMap: CodeToActionMap = {
  [httpStatusCodes.BAD_REQUEST]: { path: "/bad-request", toast: "Bad Request Error" },
  [httpStatusCodes.UNAUTHORIZED]: { path: "/unauthorized", toast: "Unauthorized Error" },
  [httpStatusCodes.FORBIDDEN]: { path: "/forbidden", toast: "Forbidden Error" },
  [httpStatusCodes.NOT_FOUND]: { path: "/not-found", toast: "Not Found Error" },
  [httpStatusCodes.BAD_GATEWAY]: { path: "/bad-gateway", toast: "Bad Gateway Error" },
  [httpStatusCodes.SERVICE_UNAVAILABLE]: {
    path: "/service-unavailable",
    toast: "Service Unavailable Error",
  },
  [httpStatusCodes.GATEWAY_TIMEOUT]: { path: "/gateway-timeout", toast: "Gateway Timeout Error" },
  [axiosErrorCodes.TIMEOUT]: { path: "/timeout", toast: "Timeout Error" },
  [axiosErrorCodes.NETWORK_ERROR]: { path: "/network-error", toast: "Network Error" },
};

/**
 * Handles errors and navigates to the appropriate page and/or displays a toast
 * @param error - The error to handle
 * @param errorActionsMap - The map of error codes to actions
 * @param includeDefaultActions - Whether to include default actions -- any codss handled in errorActionsMap will override the default actions
 * @param codesToHandle - The codes to handle
 * @param toast - The toast handler used to display the toast
 * @param navigate - The navigation handler used to navigate to the appropriate page
 * @returns An object with a boolean indicating if the error was handled
 */
export function handleError({
  error,
  errorActionsMap = {},
  includeDefaultActions = false,
  codesToHandle = [...codes],
  toast,
  navigate,
}: {
  error: unknown;
  errorActionsMap?: CodeToActionMap;
  includeDefaultActions?: boolean;
  codesToHandle?: Code[];
  toast?: Toast;
  navigate?: (to: string) => void;
}): Handled {
  const combinedErrorActionsMap = {
    ...(includeDefaultActions ? defaultErrorActionsMap : {}),
    ...errorActionsMap,
  };
  if (isAxiosError(error)) {
    const handled = codesToHandle.some(code =>
      handleAxiosErrorCode({
        error,
        errorActionsMap: combinedErrorActionsMap,
        toast,
        navigate,
        code,
      })
    );
    return { handled };
  }
  return { handled: false };
}

function handleAxiosErrorCode({
  error,
  errorActionsMap,
  code,
  toast,
  navigate,
}: {
  error: AxiosError;
  errorActionsMap: CodeToActionMap;
  code: Code;
  toast?: Toast;
  navigate?: (to: string) => void;
}): boolean {
  let handled = false;
  if (error.response?.status === code || error.code === code) {
    const actions = errorActionsMap[code];
    if (navigate && actions?.path) {
      navigate(actions.path);
      handled = true;
    }
    if (toast && actions?.toast) {
      const id = error.config?.url;
      if (!id || !toast.isActive(id)) {
        toast.error({ title: actions.toast, id, duration: TOAST_DURATION });
      }
      handled = true;
    }
  }
  return handled;
}

export function getErrorMessage(errorName: string, extraInfo?: string): string {
  return `There was ${getArticle(
    errorName
  )} ${errorName} request error, please try re-loading the app. ${
    extraInfo ? ` ${extraInfo}` : ""
  } If the problem persists, please reach out to support@metriport.com.`;
}

function getArticle(str: string): string {
  if (startsWithVowel(str)) return "an";
  return "a";
}

function startsWithVowel(str: string) {
  return /^[aeiou]/i.test(str);
}

export function handleNetworkErrorWithTryAgainToast(error: unknown, toast: Toast): Handled {
  return handleError({
    error,
    errorActionsMap: {
      [axiosErrorCodes.NETWORK_ERROR]: {
        toast:
          "There was a network error, please try again. If the issue persists, please reach out to support@metriport.com.",
      },
    },
    toast,
  });
}

export function handleTimeoutWithTryAgainToast(error: unknown, toast: Toast): Handled {
  return handleError({
    error,
    errorActionsMap: {
      [axiosErrorCodes.TIMEOUT]: {
        toast:
          "There was a timeout error, please try again. If the issue persists, please reach out to support@metriport.com.",
      },
    },
    toast,
  });
}

export function handleTimeoutOrNetworkErrorWithTryAgainToast(
  error: unknown,
  toast: Toast
): Handled {
  const { handled: handledNetworkError } = handleNetworkErrorWithTryAgainToast(error, toast);
  const { handled: handledTimeout } = handleTimeoutWithTryAgainToast(error, toast);
  return { handled: handledNetworkError || handledTimeout };
}

export function swallowTimeoutOrNetworkError(error: unknown): Handled {
  if (
    isAxiosError(error) &&
    (error.code === axiosErrorCodes.TIMEOUT || error.code === axiosErrorCodes.NETWORK_ERROR)
  ) {
    return { handled: true };
  }
  return { handled: false };
}
