import { useToast, UseToastOptions } from "@chakra-ui/react";

export type ToastOptions = Omit<UseToastOptions, "title"> &
  Required<Pick<UseToastOptions, "title">>;

export type UseMetriportToast = {
  info: (options: ToastOptions) => void;
  success: (options: ToastOptions) => void;
  warning: (options: UseToastOptions) => void;
  error: (options?: UseToastOptions) => void;
  isActive: (id: string) => boolean;
};

export default function useMetriportToast(): UseMetriportToast {
  const chakraToast = useToast();
  const duration = 2_000;
  const isClosable = true;
  return {
    isActive: chakraToast.isActive,
    info: (options: ToastOptions) =>
      chakraToast({
        status: "info",
        duration,
        isClosable,
        ...options,
      }),
    success: (options: ToastOptions) =>
      chakraToast({
        status: "success",
        duration,
        isClosable,
        ...options,
      }),
    warning: (options: UseToastOptions) =>
      chakraToast({
        status: "warning",
        duration,
        isClosable,
        ...options,
      }),
    error: (options: UseToastOptions = {}) =>
      chakraToast({
        status: "error",
        duration,
        isClosable,
        ...options,
        title: options.title ?? "Something went wrong, try again.",
      }),
  };
}
