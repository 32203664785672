import { Observation, Organization, Practitioner } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { getReferenceResources, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayText } from "../shared/occurrences";
import { showPeriodDate } from "../shared/period";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import {
  GroupedSocial,
  SocialHistoryRowData,
  getSocialHistory,
  groupSocialHistories,
} from "./table-data";

export function SocialHistoryContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & SocialHistoryRowData) {
  const observations = getResourcesFromBundle<Observation>(mappedConsolidated, "Observation");
  const socials = getSocialHistory(observations);
  const groupedSocials = groupSocialHistories(socials);
  const selectedSocial = groupedSocials.find(
    lab => lab.mostRecentObservation.id === rowContents.id
  );

  if (!selectedSocial) {
    return null;
  }

  const mostRecentSocial = selectedSocial.mostRecentObservation;
  const valueString = mostRecentSocial?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Social History"
      sourceDocument={{
        id: mostRecentSocial?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentSocial, null, 2) : undefined}
    >
      <SocialHistoryDisplay
        socialHistory={selectedSocial}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function SocialHistoryDisplay({
  socialHistory,
  tableRow,
  mappedConsolidated,
}: {
  socialHistory: GroupedSocial | undefined;
  tableRow: SocialHistoryRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!socialHistory) {
    return null;
  }

  const performerOrgReferences = getReferenceResources<Organization>(
    socialHistory?.mostRecentObservation.performer,
    "Organization",
    mappedConsolidated
  );

  const performerPractitionerReferences = getReferenceResources<Practitioner>(
    socialHistory?.mostRecentObservation.performer,
    "Practitioner",
    mappedConsolidated
  );

  const pointsDescOrder = [...(socialHistory.sortedPoints || [])];

  return (
    <>
      <DetailsDisplay
        details={{
          observation: tableRow.observation,
          value: tableRow.value,
          status: socialHistory.mostRecentObservation.status,
          period: showPeriodDate({
            period: socialHistory.mostRecentObservation.effectivePeriod,
            fallbackDate: socialHistory.mostRecentObservation.effectiveDateTime,
          }),
        }}
      />
      {pointsDescOrder && (
        <>
          <SidePanelSection title="Data Points" totalItems={pointsDescOrder.length}>
            {pointsDescOrder.map((p, index) =>
              OccurenceDisplayText(p.date, p.value.toString(), index)
            )}
          </SidePanelSection>
        </>
      )}
      <CodesDisplay code={socialHistory.mostRecentObservation.code} />
      <ReferencesDisplay
        organizations={{
          refs: performerOrgReferences,
        }}
        practitioners={{
          refs: performerPractitionerReferences,
        }}
      />
    </>
  );
}
