import { Box } from "@chakra-ui/react";
import { METRIPORT_PRIMARY_FADED } from "../../../shared-logic/style";

export function ChronicBadge() {
  return (
    <Box
      as="span"
      px="2"
      py="0.5"
      height="1.25rem"
      display="inline-flex"
      alignItems="center"
      borderRadius="full"
      fontSize="2xs"
      textTransform="uppercase"
      letterSpacing="0.05em"
      fontWeight="600"
      color="white"
      bg={METRIPORT_PRIMARY_FADED}
      transition="all 0.2s ease"
    >
      Chronic
    </Box>
  );
}
