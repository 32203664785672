import { Encounter, Condition, Organization, Practitioner } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { EncounterRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { showPeriodDate } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { getReferenceResource, getReferenceResources } from "../shared";
import { ReferencesDisplay } from "../shared/references";

export function EncounterContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & EncounterRowData) {
  const selectedEncounter = mappedConsolidated?.["Encounter"]?.[rowContents.id] as
    | Encounter
    | undefined;

  const valueString = selectedEncounter?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Encounter"
      sourceDocument={{
        id: selectedEncounter?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedEncounter, null, 2) : undefined}
    >
      <EncounterDisplay
        encounter={selectedEncounter}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function EncounterDisplay({
  encounter,
  tableRow,
  mappedConsolidated,
}: {
  encounter: Encounter | undefined;
  tableRow: EncounterRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!encounter) {
    return null;
  }

  const conditionReferences = getReferenceResources<Condition>(
    encounter.diagnosis,
    "Condition",
    mappedConsolidated
  );

  const serviceProviderReference = getReferenceResource<Organization>(
    encounter.serviceProvider,
    "Organization",
    mappedConsolidated
  );

  const participantReferences = getReferenceResources<Practitioner>(
    encounter.participant?.flatMap(p => p.individual ?? []),
    "Practitioner",
    mappedConsolidated
  );

  return (
    <>
      <DetailsDisplay
        details={{
          reasons: tableRow.reasons,
          location: tableRow.location,
          type: tableRow.type,
          period: showPeriodDate({
            period: encounter.period,
          }),
        }}
      />
      <CodesDisplay code={encounter?.reasonCode} />
      <ReferencesDisplay
        conditions={{
          refs: conditionReferences,
        }}
        practitioners={{
          refs: participantReferences,
        }}
        organizations={{
          refs: serviceProviderReference ? [serviceProviderReference] : undefined,
        }}
      />
    </>
  );
}
