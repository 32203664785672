import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { AgGridReact } from "@ag-grid-community/react";
import { useColorMode } from "@chakra-ui/react";
import { NetworkEntry, ResponseMeta } from "@metriport/api-sdk";
import { getGridClassName, IdContainer, NameCopyContainer } from "@metriport/shared-internal";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

ModuleRegistry.registerModules([InfiniteRowModelModule]);

/** Maps row number to page URL */
type RowMapping = Record<string, string>;

export type NetworkEntryGridRef = {
  resetSearch: (filters: string) => void;
  updatePage: (filters: string) => void;
};

export type Props = {
  getNetworkEntries: (
    pageUrl?: string | undefined,
    filters?: string | undefined
  ) => Promise<{ networkEntries: NetworkEntry[]; meta: ResponseMeta } | undefined>;
  isLoading: boolean;
  numItemsPerPage?: number | undefined;
};

type GridState = {
  rowMapping: RowMapping;
  filters: string;
};
const initialState: GridState = { rowMapping: {}, filters: "" };

const NetworkEntryGrid = forwardRef<NetworkEntryGridRef, React.PropsWithChildren<Props>>(
  (
    { getNetworkEntries, isLoading, numItemsPerPage = 50 }: Props,
    ref: ForwardedRef<NetworkEntryGridRef>
  ) => {
    const { colorMode } = useColorMode();
    const [networkEntries, setNetworkEntries] = useState<NetworkEntry[] | undefined>(undefined);
    const [api, setApi] = useState<GridApi | null>(null);
    const [gridState] = useState<GridState>(initialState);

    const columnDefs: ColDef[] = useMemo(() => {
      return [
        {
          field: "oid",
          headerName: "OID",
          sortable: false,
          cellClass: "clickable-cell",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return <IdContainer value={params.value} entity={"Organization"} full={true} />;
          },
          maxWidth: 280,
        },
        {
          field: "name",
          sortable: false,
          headerName: "Name",
          cellClass: "clickable-cell",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return <NameCopyContainer value={params.value} entity={"Organization Name"} />;
          },
          maxWidth: 750,
        },
        {
          field: "zipCode",
          sortable: false,
          headerName: "Zipcode",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return <div>{params.value}</div>;
          },
          maxWidth: 120,
        },
        {
          field: "state",
          sortable: false,
          headerName: "State",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return <div>{params.value}</div>;
          },
          maxWidth: 80,
        },
        {
          field: "rootOrganization",
          sortable: false,
          headerName: "Root Organization",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return <div>{params.value}</div>;
          },
          maxWidth: 250,
        },
        {
          field: "network",
          sortable: false,
          headerName: "Network",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            if (!params.value) {
              return <div></div>;
            }
            const text = params.value?.toLowerCase();
            const textToDisplay = text.charAt(0).toUpperCase() + text.slice(1);
            return <div>{textToDisplay}</div>;
          },
          maxWidth: 150,
        },
        {
          field: "managingOrgOid",
          headerName: "Managing Org OID",
          sortable: false,
          cellClass: "clickable-cell",
          onCellClicked: () => null,
          cellRenderer: (params: { value: string }) => {
            return (
              <IdContainer value={params.value} entity={"Managing Organization"} full={true} />
            );
          },
          maxWidth: 250,
        },
      ];
    }, [networkEntries]);

    const defaultColDef: ColDef = {
      flex: 1,
    };

    const getRows = useCallback(
      (api: GridApi) => async (params: IGetRowsParams) => {
        try {
          const { gridState: localGridState }: { gridState: GridState } = params.context;
          const currPageNumber = api.paginationGetCurrentPage();
          const currPageUrl = (localGridState.rowMapping ?? {})[currPageNumber];
          const filters = localGridState.filters;
          const resp = await getNetworkEntries(currPageUrl, filters);
          if (!resp) {
            params.failCallback();
            return;
          }
          const { networkEntries, meta } = resp;
          if (!networkEntries) {
            params.failCallback();
            return;
          }
          setNetworkEntries(networkEntries);
          const rowData = networkEntries;
          const totalCountOfItems = getTotalCountOfItems(meta, currPageNumber, numItemsPerPage);
          params.successCallback(rowData, totalCountOfItems);
          updateRowMapping(meta, currPageNumber, localGridState);
        } catch (error) {
          params.failCallback();
        }
      },
      [getNetworkEntries, numItemsPerPage]
    );

    const onGridReady = useCallback(
      (params: GridReadyEvent) => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: getRows(params.api),
        };
        params.api.setGridOption("datasource", dataSource);
        setApi(params.api);
      },
      [getRows]
    );

    useImperativeHandle(ref, () => ({
      resetSearch(filters: string) {
        if (api) {
          gridState.filters = filters;
          gridState.rowMapping = {};
          api.paginationGoToPage(0);
          api.purgeInfiniteCache();
        }
      },
      updatePage(filters: string) {
        if (api) {
          gridState.filters = filters;
          api.purgeInfiniteCache();
        }
      },
    }));

    return (
      <AgGridReact
        className={getGridClassName(colorMode)}
        loading={isLoading}
        headerHeight={60}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        enableCellTextSelection={true}
        pagination={true}
        suppressCellFocus={true}
        rowModelType={"infinite"}
        paginationPageSizeSelector={false}
        paginationPageSize={numItemsPerPage}
        cacheOverflowSize={1}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={0}
        maxBlocksInCache={1}
        cacheBlockSize={numItemsPerPage}
        onGridReady={onGridReady}
        context={{ gridState }}
      />
    );
  }
);

function updateRowMapping(
  paginationMeta: ResponseMeta,
  currPageNumber: number,
  myState: GridState
): void {
  const nextPageNumber = currPageNumber + 1;
  const nextPageUrl = paginationMeta.nextPage;
  const rowMappingUpdate: RowMapping = {
    ...(nextPageNumber >= 0 && nextPageUrl ? { [nextPageNumber]: nextPageUrl } : {}),
  };
  if (currPageNumber === 1 && paginationMeta.prevPage) {
    rowMappingUpdate[0] = paginationMeta.prevPage;
  }
  const newRowMapping = { ...myState.rowMapping, ...rowMappingUpdate };
  myState.rowMapping = newRowMapping;
}

function getTotalCountOfItems(
  paginationMeta: ResponseMeta,
  currPageNumber: number,
  numItemsPerPage: number
): number | undefined {
  if (!paginationMeta.nextPage) {
    const totalNumItems = paginationMeta.itemsOnPage + currPageNumber * numItemsPerPage;
    return totalNumItems;
  }
  return -1;
}

export default NetworkEntryGrid;
