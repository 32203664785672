import { SearchIcon } from "@chakra-ui/icons";
import { Box, Input, InputGroup, InputLeftElement, useColorMode } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { useState } from "react";
import { getGridClassName } from "../../shared/ag-grid";
import { METRIPORT_PRIMARY } from "../shared-logic/style";

type TableGridProps = {
  sectionTitle: string;
} & AgGridReactProps;

export function TableGrid({
  sectionTitle,
  columnDefs,
  rowData,
  headerHeight = 60,
  groupDefaultExpanded = -1,
  pagination = true,
  paginationPageSize = 20,
  rowStyle,
  suppressCellFocus = true,
  defaultColDef,
  ...restProps
}: TableGridProps) {
  const { colorMode } = useColorMode();
  const [search, setSearch] = useState("");

  const gridDefaultColDef: ColDef = {
    flex: 1,
  };

  const hasRowData = rowData && rowData.length > 0;
  const requiresPagination = hasRowData && rowData.length > paginationPageSize;

  const rowHeight = 48;
  const boxHeight = hasRowData ? rowData.length * rowHeight + 60 : 0;

  return (
    <>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
          <SearchIcon />
        </InputLeftElement>
        <Input
          minW={"300px"}
          w={"25%"}
          value={search}
          placeholder={`Search for ${sectionTitle ?? "resources"}`}
          onChange={e => setSearch(e.target.value)}
          _hover={{ borderColor: METRIPORT_PRIMARY }}
          _active={{ borderColor: METRIPORT_PRIMARY }}
          _focus={{ borderColor: METRIPORT_PRIMARY }}
        />
      </InputGroup>
      <Box id="table-container" style={{ width: "100%", height: boxHeight, maxHeight: "750px" }}>
        <AgGridReact
          {...restProps}
          className={getGridClassName(colorMode)}
          headerHeight={headerHeight}
          groupDefaultExpanded={groupDefaultExpanded}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef ?? gridDefaultColDef}
          rowData={rowData}
          quickFilterText={search}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          rowStyle={rowStyle ? rowStyle : { cursor: "pointer" }}
          suppressCellFocus={suppressCellFocus}
          suppressScrollOnNewData={true}
          suppressPaginationPanel={requiresPagination ? false : true}
          rowHeight={rowHeight}
        />
      </Box>
    </>
  );
}
