import { Condition, Extension } from "@medplum/fhirtypes";
import { ICellRendererParams } from "ag-grid-community";
import { ChronicBadge } from "./chronicity";
import { ConditionRowData } from "./table-data";

const CHRONICITY_EXTENSION_URL = "http://hl7.org/fhir/StructureDefinition/condition-related";

export const ConditionCellRenderer = (params: ICellRendererParams<ConditionRowData>) => {
  const condition = params.data?.originalData;
  const isChronic = condition ? isChronicCondition(condition) : false;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {isChronic && <ChronicBadge />}
      <span>{params.data?.condition}</span>
    </div>
  );
};

export function isChronicCondition(condition?: Condition): boolean {
  if (!condition) return false;

  const chronicityExtension = condition.extension?.find(
    (e: Extension) => e.url === CHRONICITY_EXTENSION_URL
  );

  return chronicityExtension?.valueCoding?.code === "C" ? true : false;
}
