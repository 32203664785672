import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Modal, ModalContent, Text, useColorModeValue } from "@chakra-ui/react";
import { isDownloading, OnDownloadFileParams } from "../../../shared-logic/useDownloadFile";

export function DocumentMenu({
  contentType,
  docId,
  fileName,
  onDownloadDocument,
  isDownloading,
  isOpen,
  onClose,
  positionX,
  positionY,
}: {
  contentType: string | undefined;
  docId: string | undefined;
  fileName: string | undefined;
  onDownloadDocument: (params: OnDownloadFileParams) => Promise<void>;
  isDownloading: isDownloading;
  isOpen: boolean;
  onClose: () => void;
  positionX: number | undefined;
  positionY: number | undefined;
}) {
  if (!docId || !fileName) return null;

  const isDocDownloading = isDownloading[docId]?.downloading ?? false;
  const bg = useColorModeValue("gray.50", "gray.800");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
      <ModalContent
        top={positionY ? positionY - 40 : undefined}
        left={positionX ? positionX : undefined}
        position={"absolute"}
        w={"fit-content"}
        bg={bg}
      >
        {isXml(contentType) ? (
          <>
            <Button
              leftIcon={<DownloadIcon />}
              onClick={() => {
                onDownloadDocument({
                  docId,
                  docFilename: fileName,
                  docExtension: contentType,
                  conversionType: "pdf",
                });
                onClose();
              }}
              isLoading={isDocDownloading && isDownloading[docId]?.type === "pdf"}
            >
              <Text mr={"auto"}>PDF</Text>
            </Button>
            <Button
              leftIcon={<DownloadIcon />}
              onClick={() => {
                onDownloadDocument({
                  docId,
                  docFilename: fileName,
                  docExtension: contentType,
                  conversionType: "html",
                });
                onClose();
              }}
              isLoading={isDocDownloading && isDownloading[docId]?.type === "html"}
            >
              <Text mr={"auto"}>HTML</Text>
            </Button>
            <Button
              leftIcon={<DownloadIcon />}
              onClick={() => {
                onDownloadDocument({
                  docId,
                  docFilename: fileName,
                  docExtension: contentType,
                });
                onClose();
              }}
              isLoading={isDocDownloading && isDownloading[docId]?.type === "xml"}
            >
              <Text mr={"auto"}>XML</Text>
            </Button>
          </>
        ) : (
          <Button
            leftIcon={<DownloadIcon />}
            onClick={() => {
              onDownloadDocument({
                docId,
                docFilename: fileName,
                docExtension: contentType,
              });
              onClose();
            }}
            isLoading={isDocDownloading}
          >
            <Text mr={"auto"}>Download</Text>
          </Button>
        )}
      </ModalContent>
    </Modal>
  );
}

export function isXml(mimeType: string | undefined): boolean {
  return mimeType ? mimeType.includes("xml") : false;
}
