import { ReactNode, useState } from "react";
import { Text, Box, useColorModeValue, Flex } from "@chakra-ui/react";
import { Button } from "../../button";

const DEFAULT_DATAPOINTS = 10;

interface SidePanelSectionProps {
  title: string;
  children: ReactNode[];
  totalItems?: number;
}

export function SidePanelSection({ title, children, totalItems = 0 }: SidePanelSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const content = Array.isArray(children)
    ? children.slice(0, isExpanded ? children.length : DEFAULT_DATAPOINTS)
    : children;

  return (
    <Box mb={3}>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize={"2xl"} fontWeight="bold">
          {title}:
        </Text>
        {isLargerThanDefault(totalItems) && (
          <Button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "See Less" : "View All"}
          </Button>
        )}
      </Flex>
      <Box
        px={4}
        py={2}
        border="solid"
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        {content}
      </Box>
    </Box>
  );
}

function isLargerThanDefault(numItems: number | undefined): boolean {
  if (!numItems) return false;

  return numItems > DEFAULT_DATAPOINTS;
}
