import { Bundle, Resource } from "@medplum/fhirtypes";
import { ConsolidatedQuery } from "@metriport/api-sdk";
import dayjs from "dayjs";
import { useState } from "react";
import { useInterval } from "./use-interval";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import axios from "axios";
import { getConsolidatedWebhook } from "../../../api/consolidated-bundle";
import { capture } from "../../../shared/capture";
import { swallowTimeoutOrNetworkError } from "../../error/utils";

type UseGetConsolidated = {
  isQueryingConsolidated: boolean;
  consolidated: Bundle<Resource> | undefined;
  onQueryPatientConsolidated: () => Promise<void>;
};

const POLLING_INTERVAL = dayjs.duration(1, "second").asMilliseconds();
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function useGetConsolidated({ patientId }: { patientId: string }): UseGetConsolidated {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [isQueryingConsolidated, setIsQueryingConsolidated] = useState(false);
  const [consolidatedRequestId, setConsolidatedRequestId] = useState<string | undefined>(undefined);
  const [consolidated, setConsolidated] = useState<Bundle<Resource> | undefined>(undefined);

  async function onQueryPatientConsolidated() {
    try {
      setIsQueryingConsolidated(true);
      const query = await metriportApi.startConsolidatedQuery(
        patientId,
        undefined,
        undefined,
        undefined,
        "json",
        true
      );
      setConsolidatedRequestId(query.requestId);
      toast.info({
        title:
          "Loading the patient's consolidated data. This may take some time depending on how much data is available.",
        duration: EXPLAIN_TOAST_DURATION,
      });
    } catch (error) {
      const msg = "Failed to start patient consolidated query";
      capture.error(msg, {
        extra: { patient: patientId, context: `patient.get.consolidated`, error },
      });
      toast.error({ title: msg });
      setIsQueryingConsolidated(false);
    }
  }

  useInterval(
    async () => {
      function reset() {
        setIsQueryingConsolidated(false);
        setConsolidatedRequestId(undefined);
      }

      if (!consolidatedRequestId) return;

      let queries: ConsolidatedQuery[] | null = null;
      try {
        const { queries: queriesFromApi } = await metriportApi.getConsolidatedQueryStatus(
          patientId
        );
        queries = queriesFromApi;
      } catch (error) {
        const { handled } = swallowTimeoutOrNetworkError(error);
        if (!handled) {
          const msg = "Failed to get patient consolidated query status";
          capture.error(msg, {
            extra: { patient: patientId, context: `patient.get.consolidated-query-status` },
          });
          toast.error({ title: msg });
          reset();
        }
        return;
      }
      // TODO Need to eventually exit polling if the query is not completed
      const completed = queries?.find(
        query => query.status === "completed" && query.requestId === consolidatedRequestId
      );
      if (!completed) return;

      const webhook = await getConsolidatedWebhook(patientId, consolidatedRequestId);
      const { fileUrl } = webhook;

      if (!fileUrl) {
        const msg = "Failed to get patient consolidated data webhook file url";
        capture.error(msg, {
          extra: { patient: patientId, context: `patient.get.consolidated-webhook`, webhook },
        });
        toast.error({ title: msg });
        reset();
        return;
      }

      try {
        const response = await axios.get(fileUrl);
        if (response.data) {
          setConsolidated(response.data as Bundle<Resource>);
        }
      } catch (error) {
        const msg = "Failed to get patient consolidated data";
        capture.error(msg, {
          extra: { patient: patientId, context: `patient.get.consolidated-data`, webhook, error },
        });
        toast.error({ title: msg });
      } finally {
        reset();
      }
    },
    isQueryingConsolidated ? POLLING_INTERVAL : null
  );

  return { isQueryingConsolidated, consolidated, onQueryPatientConsolidated };
}
