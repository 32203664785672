import { DiagnosticReport, Organization, Practitioner, Procedure } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { getReferenceResource, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayText } from "../shared/occurrences";
import { showPeriodDate } from "../shared/period";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import { GroupedProcedures, ProcedureRowData, groupProcedures } from "./table-data";

export function ProcedureContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ProcedureRowData) {
  const procedures = getResourcesFromBundle<Procedure>(mappedConsolidated, "Procedure");
  const groupedProcedures = groupProcedures(procedures);
  const selectedProcedure = groupedProcedures.find(
    conditions => conditions.mostRecentProcedure.id === rowContents.id
  );

  if (!selectedProcedure) return null;

  const mostRecentProcedure = selectedProcedure.mostRecentProcedure;

  const valueString =
    mostRecentProcedure?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Procedure"
      sourceDocument={{
        id: mostRecentProcedure?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentProcedure, null, 2) : undefined}
    >
      <ProcedureDisplay
        procedure={selectedProcedure}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function ProcedureDisplay({
  procedure,
  tableRow,
  mappedConsolidated,
}: {
  procedure: GroupedProcedures | undefined;
  tableRow: ProcedureRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!procedure) {
    return null;
  }

  const practitioners: Practitioner[] = [];
  const organizations: Organization[] = [];
  const reports: DiagnosticReport[] = [];

  procedure.mostRecentProcedure.performer?.forEach(perf => {
    if (perf.actor?.reference?.startsWith("Practitioner")) {
      const pract = getReferenceResource<Practitioner>(
        perf.actor,
        "Practitioner",
        mappedConsolidated
      );
      if (pract) practitioners.push(pract);
      return;
    }

    if (perf.actor?.reference?.startsWith("Organization")) {
      const org = getReferenceResource<Organization>(
        perf.actor,
        "Organization",
        mappedConsolidated
      );
      if (org) organizations.push(org);
      return;
    }
  });

  procedure.mostRecentProcedure.report?.forEach(report => {
    if (report.reference?.startsWith("DiagnosticReport")) {
      const diagReport = getReferenceResource<DiagnosticReport>(
        report,
        "DiagnosticReport",
        mappedConsolidated
      );
      if (diagReport) reports.push(diagReport);
    }
  });

  return (
    <>
      <DetailsDisplay
        details={{
          procedure: tableRow.procedure,
          status: tableRow.status,
          period: showPeriodDate({
            period: procedure.mostRecentProcedure.performedPeriod,
            fallbackDate: procedure.mostRecentProcedure.performedDateTime,
          }),
        }}
      />
      {procedure.sortedOccurrences && procedure.sortedOccurrences.length > 1 && (
        <>
          <SidePanelSection title="Occurrences">
            {procedure.sortedOccurrences.map((p, index) =>
              OccurenceDisplayText(p.date, p.time, index)
            )}
          </SidePanelSection>
        </>
      )}
      <CodesDisplay code={procedure.mostRecentProcedure.code} />
      <ReferencesDisplay
        practitioners={{
          refs: practitioners,
        }}
        organizations={{
          refs: organizations,
        }}
        reports={{
          refs: reports,
        }}
      />
    </>
  );
}
