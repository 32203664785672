import { DocumentQuery, ListDocumentResult } from "@metriport/api-sdk";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getDocumentQueryStatus } from "../../../api/medical-record";
import { capture } from "../../../shared/capture";
import { swallowTimeoutOrNetworkError } from "../../error/utils";
import { useInterval } from "./use-interval";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";

export type SearchDocsFilters = {
  dateFrom?: string;
  dateTo?: string;
  content?: string;
  setFiltersInStorage?: boolean;
};

type UseQueryDocuments = {
  isQueryingDocuments: boolean;
  isListingDocuments: boolean;
  isQueryError: boolean;
  dqProgress: DocumentQuery;
  documentListResult: ListDocumentResult | undefined;
  initialDocumentListResult: ListDocumentResult | undefined;
  lastDocQuery: string | undefined;
  searchDocFilters: SearchDocsFilters | undefined;
  onQueryPatientDocuments: () => Promise<void>;
  onSearchPatientDocuments: (filters: SearchDocsFilters) => Promise<void>;
};

const POLLING_INTERVAL = dayjs.duration(3, "second").asMilliseconds();
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function useQueryDocuments({ patientId }: { patientId: string }): UseQueryDocuments {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [isQueryingDocuments, setIsQueryingDocuments] = useState(false);
  const [isListingDocuments, setIsListingDocuments] = useState(false);
  const [isQueryError, setIsQueryError] = useState(false);
  const [dqProgress, setDqProgress] = useState<DocumentQuery>({});
  const [lastDocQuery, setLastDocQuery] = useState<string | undefined>(undefined);
  const [documentListResult, setDocuments] = useState<ListDocumentResult | undefined>(undefined);
  const [initialDocumentListResult, setInitialDocuments] = useState<ListDocumentResult | undefined>(
    undefined
  );
  const [searchDocFilters, setSearchDocFilters] = useState<SearchDocsFilters | undefined>(
    undefined
  );

  useEffect(() => {
    let setFilters = false;
    const filters = localStorage.getItem("doc_filters");
    if (filters) {
      const globalFilters: { [key: string]: SearchDocsFilters } = JSON.parse(filters);
      const patientFilters = globalFilters[patientId];
      if (patientFilters) {
        setSearchDocFilters(patientFilters);
        setFilters = true;
      }
    }
    if (!setFilters) {
      setSearchDocFilters({
        dateFrom: undefined,
        dateTo: undefined,
        content: undefined,
      });
    }
    metriportApi.listDocuments(patientId).then(documents => setInitialDocuments(documents));
    getDocumentQueryStatus(patientId).then(resp => {
      setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
    });
  }, [patientId]);

  async function onQueryPatientDocuments() {
    try {
      setIsQueryingDocuments(true);
      await metriportApi.startDocumentQuery(patientId);
      toast.info({
        title:
          "Querying the patient's documents. This may take some time depending on how how many documents are available.",
        duration: EXPLAIN_TOAST_DURATION,
      });
    } catch (error) {
      const msg = "Failed to query patient documents";
      capture.error(msg, {
        extra: { patient: patientId, context: `patient.file.list`, error },
      });
      toast.error({ title: msg });
      setIsQueryingDocuments(false);
      setIsQueryError(true);
    }
  }

  useInterval(
    async () => {
      let status: DocumentQuery | null = null;
      try {
        const statusFromApi = await metriportApi.getDocumentQueryStatus(patientId);
        status = statusFromApi;
      } catch (error) {
        const { handled } = swallowTimeoutOrNetworkError(error);
        if (!handled) {
          const msg = "Failed to get patient document query status";
          capture.error(msg, {
            extra: { patient: patientId, context: `patient.get.document-query-status` },
          });
          toast.error({ title: msg });
          setIsQueryingDocuments(false);
          setIsQueryError(true);
        }
        return;
      }

      const { download, convert } = status;

      if (download?.status !== "processing" && dqProgress?.download?.status === "processing") {
        onSearchPatientDocuments({ ...searchDocFilters, setFiltersInStorage: false });
      }

      if (convert?.status !== "processing") {
        setIsQueryingDocuments(false);
        const resp = await getDocumentQueryStatus(patientId);
        setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
      }

      setDqProgress({
        download,
        convert,
      });
    },
    isQueryingDocuments ? POLLING_INTERVAL : null
  );

  async function onSearchPatientDocuments({
    dateFrom,
    dateTo,
    content,
    setFiltersInStorage = true,
  }: SearchDocsFilters) {
    if (setFiltersInStorage) {
      const filters = localStorage.getItem("doc_filters");
      const globalFilters = filters ? JSON.parse(filters) : {};

      const newFilters = {
        ...globalFilters,
        [patientId]: {
          dateFrom,
          dateTo,
          content,
        },
      };

      localStorage.setItem("doc_filters", JSON.stringify(newFilters));
      setSearchDocFilters({
        dateFrom,
        dateTo,
        content,
      });
    }

    try {
      setIsListingDocuments(true);
      const dateFromValue = getFilterValue(dateFrom);
      const dateToValue = getFilterValue(dateTo);
      const contentValue = getFilterValue(content);

      const documentList = await metriportApi.listDocuments(patientId, {
        dateFrom: dateFromValue,
        dateTo: dateToValue,
        content: contentValue,
      });
      setDocuments(documentList);
    } catch (error) {
      const msg = "Failed to search documents";
      capture.error(msg, {
        extra: { patient: patientId, context: `patient.documents.list`, error },
      });
      toast.error({ title: msg });
      setIsQueryError(true);
    } finally {
      setIsListingDocuments(false);
    }
  }

  return {
    isQueryingDocuments,
    isListingDocuments,
    isQueryError,
    dqProgress,
    documentListResult,
    initialDocumentListResult,
    lastDocQuery,
    searchDocFilters,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
  };
}

function getFilterValue(filterInput: string | undefined): string | undefined {
  if (!filterInput) return;
  const trimmedValue = filterInput.trim();
  return trimmedValue && trimmedValue.length > 0 ? trimmedValue : undefined;
}
