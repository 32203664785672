import { DocumentReference, DocumentReferenceContent } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { bytesToSize } from "../../../../../shared/util";
import { formatDate } from "../../../../shared/date";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";
const METRIPORT_CODE = "METRIPORT";

export type DocumentRowData = {
  id: string;
  fileName: string;
  description: string;
  size: string;
  fileType: string;
  organization: string;
  date: string;
};

export function buildDocumentTableData({ bundle, tableFilters }: GenerateTableDataParams) {
  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "fileName", hide: true },
    { field: "description" },
    { field: "size" },
    { field: "fileType" },
    { field: "organization" },
    { field: "date", sort: tableFilters?.stringFilter ? undefined : "desc" },
  ];

  const documents = getResourcesFromBundle<DocumentReference>(bundle, "DocumentReference");

  return {
    columnDefs,
    rowData: getDocumentRowData({ documents, tableFilters }),
  };
}

function getDocumentRowData({
  documents,
  tableFilters,
}: {
  documents: DocumentReference[];
  tableFilters: MrFilterSetting | undefined;
}): DocumentRowData[] {
  return documents
    ?.map(documentReference => {
      const metriportContent = getMetriportContent(documentReference);
      const contentType = metriportContent?.attachment?.contentType;
      const fileName = metriportContent?.attachment?.title;

      return {
        id: documentReference.id ?? "-",
        fileName: fileName ?? "-",
        description: documentReference.description ?? "-",
        size: getDocumentSize(documentReference),
        fileType: contentType ?? "-",
        organization: getOrganizationName(documentReference),
        date: documentReference.date ? formatDate(documentReference.date, ISO_DATE) : "-",
      };
    })
    .filter(row => filterByDate(row.date, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

export const getDocumentSize = (doc: DocumentReference): string => {
  if (doc.content) {
    const metriportContent = doc.content.find(isMetriportContent);

    if (metriportContent?.attachment?.size) {
      return bytesToSize(metriportContent.attachment.size);
    }

    return "-";
  }

  return "-";
};

export function getMetriportContent(doc: DocumentReference): DocumentReferenceContent | undefined {
  if (!doc || !doc.content) return undefined;

  const contents = doc.content.filter(isMetriportContent);
  // B64 Attachment Extension
  if (
    contents.length === 0 &&
    doc.extension?.some(ext => ext.url?.endsWith("doc-id-extension.json"))
  ) {
    return doc.content[0];
  }
  return contents[0];
}

function isMetriportContent(content: DocumentReferenceContent): boolean {
  return !!content.extension?.some(ext => ext.valueCoding?.code === METRIPORT_CODE);
}

export const getOrganizationName = (doc: DocumentReference): string => {
  if (doc.contained) {
    const org = doc.contained.flatMap(c => (c.resourceType === "Organization" ? c : []))[0];
    if (org?.name) return org.name;

    return "-";
  }
  return "-";
};
