import { Bundle, Resource, DocumentReference } from "@medplum/fhirtypes";
import { groupBundleByResourceType } from "../utils/group-bundle-by-resource-type";

export type ResourceMap = {
  [resourceId: string]: Resource;
};

export type MappedConsolidatedResources = {
  [resourceType: string]: ResourceMap;
};

export type PatientConsolidatedState = {
  [patientId: string]: {
    consolidated?: MappedConsolidatedResources;
    documents?: DocumentReference[];
    lastDocQuery?: string;
    initialDqRun?: string;
  };
};

export enum PatientConsolidatedStateActionType {
  setPatientsConsolidated,
  setPatientsDocuments,
  setPatientsInitialDqRun,
  setPatientsLastQueryAt,
  resetPatientsConsolidated,
}

export type PatientQueryParams = {
  patientTab: PatientTab;
  sectionName: string | undefined;
  resourceId: string | undefined;
};

export type PatientQueryParamProps = PatientQueryParams & {
  setPatientQueryParams: ({
    sectionName,
    resourceId,
    patientTab,
  }: Partial<PatientQueryParams>) => void;
  clearPatientQueryParams: () => void;
};

export const patientTabs = ["fhir", "documents", "matches"] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPatientTab(tab: any): tab is PatientTab {
  return patientTabs.includes(tab);
}

export type PatientTab = (typeof patientTabs)[number];
export const defaultPatientTab: PatientTab = "fhir";

export function getValidPatientTab(tab: string | undefined | null): PatientTab {
  if (tab && isPatientTab(tab)) return tab;
  return defaultPatientTab;
}

export type PatientConsolidatedStateAction =
  | {
      type: PatientConsolidatedStateActionType.setPatientsConsolidated;
      patientId: string;
      consolidatedBundle: Bundle<Resource>;
    }
  | {
      type: PatientConsolidatedStateActionType.setPatientsDocuments;
      patientId: string;
      documents: DocumentReference[];
    }
  | {
      type: PatientConsolidatedStateActionType.setPatientsInitialDqRun;
      patientId: string;
      initialDqRun: string;
    }
  | {
      type: PatientConsolidatedStateActionType.setPatientsLastQueryAt;
      patientId: string;
      lastDocQuery: string | undefined;
    }
  | {
      type: PatientConsolidatedStateActionType.resetPatientsConsolidated;
      patientId: string;
    };

export const reducer = (
  state: PatientConsolidatedState,
  action: PatientConsolidatedStateAction
) => {
  switch (action.type) {
    case PatientConsolidatedStateActionType.setPatientsConsolidated: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          consolidated: groupBundleByResourceType(action.consolidatedBundle),
        },
      };
    }

    case PatientConsolidatedStateActionType.setPatientsDocuments: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          documents: action.documents,
        },
      };
    }

    case PatientConsolidatedStateActionType.setPatientsInitialDqRun: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          initialDqRun: action.initialDqRun,
        },
      };
    }

    case PatientConsolidatedStateActionType.setPatientsLastQueryAt: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          lastDocQuery: action.lastDocQuery,
        },
      };
    }

    case PatientConsolidatedStateActionType.resetPatientsConsolidated: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          consolidated: undefined,
        },
      };
    }

    default:
      return state;
  }
};

export const initialState: PatientConsolidatedState = {};
