import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, HStack, Heading, Switch, Text, useColorModeValue } from "@chakra-ui/react";
import { ColDef } from "ag-grid-community";
import { MrFilterSetting } from "../../../../api/settings";
import { METRIPORT_PRIMARY_FADED } from "../../shared-logic/style";
import { Button } from "../button";
import { NoPatientData } from "../no-data";
import { TableGrid } from "../table-grid";
import { DetailedReportRowData } from "./labs/shared";
import { useState } from "react";

export type ExpandedViewProps = {
  selectedGrouping: DetailedReportRowData | undefined;
  setSelectedGrouping: (r: DetailedReportRowData | undefined) => void;
  clearSelectedGrouping: () => void;
};

export type ViewModeProps = {
  viewMode: string;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  handleToggle: () => void;
};

export type ToggleProps = {
  default: string;
  alternative: string;
};

export function FhirSection({
  sectionId,
  sectionName,
  columnDefs,
  rowData: defaultRowData,
  alternativeRowData,
  filters,
  actions,
  toggleProps,
  expandedViewProps,
  isLimited = false,
}: {
  sectionId: string;
  sectionName: string;
  columnDefs: ColDef[];
  rowData: object[];
  alternativeRowData?: object[];
  filters: MrFilterSetting | undefined;
  actions?: {
    onRowCellClicked: (content: object) => void;
  };
  toggleProps?: ToggleProps;
  expandedViewProps?: ExpandedViewProps;
  isLimited?: boolean;
}) {
  const [currentMode, setCurrentMode] = useState<string | undefined>(
    toggleProps?.default ?? undefined
  );
  const [rowData, setRowData] = useState<object[]>(defaultRowData);

  const onToggle = () => {
    if (!toggleProps || !alternativeRowData) return;

    setCurrentMode(prev => {
      if (prev === toggleProps.default) {
        setRowData(alternativeRowData);
        return toggleProps.alternative;
      } else {
        setRowData(defaultRowData);
        return toggleProps.default;
      }
    });
  };

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      actions?.onRowCellClicked(e.data);
    },
    cellStyle: params => {
      if (!params.data || !filters?.stringFilter) {
        return undefined;
      }
      const filtersArray = filters.stringFilter.split(",");
      const hasFilter = filtersArray.some(filter => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.values(params.data).some((value: any) => {
          if (value === undefined) return false;
          return value.toString().toLowerCase().trim().includes(filter.toLowerCase().trim());
        });
      });
      return hasFilter ? { backgroundColor: METRIPORT_PRIMARY_FADED } : undefined;
    },
  };

  return (
    <Box
      id={sectionId}
      w={"100%"}
      mb={5}
      borderRadius={10}
      bg={useColorModeValue("white", "gray.800")}
      px={7}
      py={5}
      border="solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <HStack mb={5}>
        <Heading size={isLimited ? "xs" : "md"}>{sectionName}</Heading>
        {toggleProps && alternativeRowData && (
          <Box
            ms={3}
            border="solid"
            borderRadius={"30px"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <HStack ms={5} me={5} fontWeight={"medium"}>
              <Text m={2}>{toggleProps.default}</Text>
              <Switch
                isChecked={currentMode === toggleProps.alternative}
                onChange={onToggle}
                colorScheme={"blue"}
                size={"lg"}
              />
              <Text m={2}>{toggleProps.alternative}</Text>
            </HStack>
          </Box>
        )}
      </HStack>
      {expandedViewProps?.selectedGrouping && (
        <HStack mb={3}>
          <Button
            onClick={() => expandedViewProps.clearSelectedGrouping()}
            variant="ghost"
            leftIcon={<ArrowBackIcon />}
            aria-label="Back"
          >
            Back
          </Button>
          <Heading m={3} size={"md"}>
            {expandedViewProps?.selectedGrouping.name} -{" "}
            {expandedViewProps?.selectedGrouping.date ? (
              <Text as="span" fontSize="lg" fontWeight={"medium"}>
                {expandedViewProps?.selectedGrouping.date}
              </Text>
            ) : (
              ""
            )}
          </Heading>
        </HStack>
      )}
      {rowData.length > 0 ? (
        <TableGrid
          sectionTitle={sectionName}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
        />
      ) : (
        <NoPatientData />
      )}
    </Box>
  );
}

/**
 * This function is only currently used to display the Labs section with its
 * two different data sets and underlying behavior.
 *
 * This component does not control its own toggle state, and is instead letting
 * the caller to control it.
 *
 * WARNING: use FhirSection for all your sections.
 */
export function FhirSectionLegacyVersion({
  sectionId,
  sectionName,
  columnDefs,
  rowData,
  filters,
  actions,
  toggleProps,
  viewModeProps,
  expandedViewProps,
  isLimited = false,
}: {
  sectionId: string;
  sectionName: string;
  columnDefs: ColDef[];
  rowData: object[];
  filters: MrFilterSetting | undefined;
  actions?: {
    onRowCellClicked: (content: object) => void;
  };
  toggleProps: ToggleProps;
  viewModeProps?: ViewModeProps;
  expandedViewProps?: ExpandedViewProps;
  isLimited?: boolean;
}) {
  const { viewMode, handleToggle } = viewModeProps || {};
  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      actions?.onRowCellClicked(e.data);
    },
    cellStyle: params => {
      if (!params.data || !filters?.stringFilter) {
        return undefined;
      }
      const filtersArray = filters.stringFilter.split(",");
      const hasFilter = filtersArray.some(filter => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.values(params.data).some((value: any) => {
          if (value === undefined) return false;
          return value.toString().toLowerCase().trim().includes(filter.toLowerCase().trim());
        });
      });
      return hasFilter ? { backgroundColor: METRIPORT_PRIMARY_FADED } : undefined;
    },
  };

  return (
    <Box
      id={sectionId}
      w={"100%"}
      mb={5}
      borderRadius={10}
      bg={useColorModeValue("white", "gray.800")}
      px={7}
      py={5}
      border="solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <HStack mb={5}>
        <Heading size={isLimited ? "xs" : "md"}>{sectionName}</Heading>
        <Box
          ms={3}
          border="solid"
          borderRadius={"30px"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
        >
          <HStack ms={5} me={5} fontWeight={"medium"}>
            <Text m={2}>{toggleProps.default}</Text>
            <Switch
              isChecked={viewMode === toggleProps.alternative}
              onChange={handleToggle}
              colorScheme={"blue"}
              size={"lg"}
            />
            <Text m={2}>{toggleProps.alternative}</Text>
          </HStack>
        </Box>
      </HStack>
      {expandedViewProps?.selectedGrouping && (
        <HStack mb={3}>
          <Button
            onClick={() => expandedViewProps.clearSelectedGrouping()}
            variant="ghost"
            leftIcon={<ArrowBackIcon />}
            aria-label="Back"
          >
            Back
          </Button>
          <Heading m={3} size={"md"}>
            {expandedViewProps?.selectedGrouping.name} -{" "}
            {expandedViewProps?.selectedGrouping.date ? (
              <Text as="span" fontSize="lg" fontWeight={"medium"}>
                {expandedViewProps?.selectedGrouping.date}
              </Text>
            ) : (
              ""
            )}
          </Heading>
        </HStack>
      )}
      {rowData.length > 0 ? (
        <TableGrid
          sectionTitle={sectionName}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
        />
      ) : (
        <NoPatientData />
      )}
    </Box>
  );
}
