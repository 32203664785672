import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Spinner,
  Tag,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { DocumentReference, Resource } from "@medplum/fhirtypes";
import { PointerEvent, useState } from "react";
import { useDownloadFile } from "../shared-logic/useDownloadFile";
import { SearchDocsFilters } from "../shared-logic/useQueryDocuments";
import { Button } from "./button";
import { DocumentMenu } from "./fhir-sections/documents/document-menu";
import { buildDocumentTableData, DocumentRowData } from "./fhir-sections/documents/table-data";
import { NoPatientData } from "./no-data";
import { SearchModal } from "./search-modal";
import { TableGrid } from "./table-grid";

type DocumentModal = DocumentRowData & {
  positionX: number;
  positionY: number;
};

export function RawDocuments({
  searchDocFilters,
  documents,
  isListingDocuments,
  actions,
  isLimited = false,
}: {
  searchDocFilters: SearchDocsFilters | undefined;
  documents: DocumentReference[] | undefined;
  isListingDocuments: boolean;
  actions: {
    onSearchDocuments: (filters: SearchDocsFilters) => Promise<void>;
  };
  isLimited?: boolean;
}) {
  const { onDownloadFile, isDownloading } = useDownloadFile();
  const searchDocumentsDisclosure = useDisclosure();
  const downloadDocumentDisclosure = useDisclosure();

  const [currentDocument, setCurrentDocument] = useState<DocumentModal | undefined>(undefined);

  const mappedDocumentReferences: { [resourceId: string]: Resource } = {};

  documents?.forEach(doc => {
    if (doc.id) {
      mappedDocumentReferences[doc.id] = doc;
    }
  });

  const { columnDefs, rowData } = buildDocumentTableData({
    bundle: {
      ["DocumentReference"]: mappedDocumentReferences,
    },
    tableFilters: undefined,
  });

  return (
    <Box mt={4}>
      <Box
        id={"raw-documents"}
        w={"100%"}
        mb={5}
        borderRadius={10}
        bg={useColorModeValue("white", "gray.800")}
        px={7}
        py={5}
        border="solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Flex mb={4} flexDirection={"row"} justifyContent={"space-between"}>
          <Heading size={isLimited ? "xs" : "md"}>Raw Documents</Heading>
          <Flex flexDirection={{ base: "column-reverse", xl: "row" }}>
            {searchDocFilters && <SelectedFilters filters={searchDocFilters} />}
            <Flex flexDirection={"row"}>
              {currentDocument &&
                currentDocument?.id &&
                isDownloading[currentDocument?.id]?.downloading && (
                  <Box alignSelf={"center"} mr={5}>
                    <Spinner />
                  </Box>
                )}
              <Button mb={{ base: "2", xl: 0 }} onClick={searchDocumentsDisclosure.onOpen}>
                Filters
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <DocumentMenu
          contentType={currentDocument?.fileType}
          docId={currentDocument?.id}
          fileName={currentDocument?.fileName}
          positionX={currentDocument?.positionX}
          positionY={currentDocument?.positionY}
          onDownloadDocument={onDownloadFile}
          isDownloading={isDownloading}
          isOpen={downloadDocumentDisclosure.isOpen}
          onClose={() => {
            downloadDocumentDisclosure.onClose();
          }}
        />
        {documents && documents.length === 0 ? (
          <Skeleton height={"full"} isLoaded={!isListingDocuments}>
            <NoPatientData />
          </Skeleton>
        ) : (
          <Box position={"relative"}>
            <Skeleton height={"full"} isLoaded={documents !== undefined && !isListingDocuments}>
              <TableGrid
                sectionTitle={"Documents"}
                columnDefs={columnDefs}
                rowData={rowData}
                gridOptions={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onRowClicked: (params: any) => {
                    const event = params.event as PointerEvent;
                    setCurrentDocument({
                      ...params.data,
                      positionY: event.clientY,
                      positionX: event.clientX,
                    });
                    downloadDocumentDisclosure.onOpen();
                  },
                }}
              />
            </Skeleton>
          </Box>
        )}
      </Box>
      <SearchModal
        isOpen={searchDocumentsDisclosure.isOpen}
        onClose={searchDocumentsDisclosure.onClose}
        onSearchDocuments={actions.onSearchDocuments}
        isFetchingDocuments={isListingDocuments}
        searchDocFilters={searchDocFilters}
      />
    </Box>
  );
}

function SelectedFilters({ filters }: { filters: SearchDocsFilters }) {
  return (
    <>
      {filters.dateFrom && <FilterTag filter={`Date From: ${filters.dateFrom}`} />}
      {filters.dateTo && <FilterTag filter={`Date To: ${filters.dateTo}`} />}
      {filters.content && <FilterTag filter={`Content: ${filters.content.substring(0, 10)}...`} />}
    </>
  );
}

function FilterTag({ filter }: { filter: string }) {
  return (
    <Tag
      variant="subtle"
      colorScheme="purple"
      size={"lg"}
      mr={{ base: 0, xl: 2 }}
      mb={{ base: 2, xl: 0 }}
    >
      {filter}
    </Tag>
  );
}
