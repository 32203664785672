import { useRef } from "react";

export function getRefAndUpdateHeight(setHeaderHeight: (height: number) => void): {
  elementRef: React.RefObject<HTMLDivElement>;
  updateHeight: () => void;
} {
  const elementRef = useRef<HTMLDivElement>(null);
  const updateHeight = () => {
    if (elementRef.current) {
      setHeaderHeight(elementRef.current.offsetHeight);
    }
  };
  return { elementRef, updateHeight };
}
