import {
  Condition,
  DiagnosticReport,
  Encounter as FHIREncounter,
  Location,
  Organization,
  Practitioner,
} from "@medplum/fhirtypes";
import { toTitleCase } from "@metriport/shared";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { UNKNOWN_DISPLAY, compare, filterByDate, getResourcesFromBundle } from "../shared";
import { getDiagnosisText } from "./report-content";

export type Report = {
  encounter: FHIREncounter;
  participants: Practitioner[];
  locations?: Location[];
  organizations?: Organization[];
  diagnoses: Condition[];
  diagnosticReports: DiagnosticReport[];
};

export type ReportRowData = {
  id: string;
  report: Report;
  typeOfReport: string;
  reasonForVisit: string;
  location: string;
  date: string;
};

export const reportTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<ReportRowData>[] = [
    { field: "id", hide: true },
    { field: "report", hide: true },
    { field: "typeOfReport" },
    { field: "reasonForVisit" },
    { field: "location" },
    { field: "date", sort: tableFilters?.stringFilter ? undefined : "desc" },
  ];

  const reports = getEncounterNotesFromBundle(bundle);

  return {
    columnDefs,
    rowData: getReportRowData({ reports, tableFilters }),
  };
};

export function getEncounterNotesFromBundle(
  bundle: MappedConsolidatedResources | undefined
): Report[] {
  const encounters = getResourcesFromBundle<FHIREncounter>(bundle, "Encounter");

  const reports: Report[] = [];

  if (!bundle) {
    return reports;
  }

  const allDiagnosticReports = getResourcesFromBundle<DiagnosticReport>(bundle, "DiagnosticReport");

  for (const encounter of encounters) {
    const participants: Practitioner[] = [];
    const locations: Location[] = [];
    const diagnoses: Condition[] = [];
    const diagnosticReports: DiagnosticReport[] = [];

    const participantIds =
      encounter.participant?.map(p => p.individual?.reference?.split("/").pop()) ?? [];
    const locationIds = encounter.location?.map(l => l.location?.reference?.split("/").pop()) ?? [];
    const diagnosisIds =
      encounter.diagnosis?.map(d => d.condition?.reference?.split("/").pop()) ?? [];

    for (const participantId of participantIds) {
      if (!participantId) {
        continue;
      }

      const practitioner = bundle.Practitioner?.[participantId];

      if (practitioner) {
        participants.push(practitioner as Practitioner);
      }
    }

    for (const locationId of locationIds) {
      if (!locationId) {
        continue;
      }

      const location = bundle.Location?.[locationId];

      if (location) {
        locations.push(location as Location);
      }
    }

    for (const diagnosisId of diagnosisIds) {
      if (!diagnosisId) {
        continue;
      }

      const diagnosis = bundle.Condition?.[diagnosisId];

      if (diagnosis) {
        diagnoses.push(diagnosis as Condition);
      }
    }

    for (const diagnosticReport of allDiagnosticReports) {
      if (diagnosticReport.encounter?.reference?.split("/").pop() === encounter.id) {
        diagnosticReports.push(diagnosticReport);
      }
    }

    reports.push({
      encounter,
      participants,
      locations,
      diagnoses,
      diagnosticReports,
    });
  }

  // Deal with DiagReports that don't reference Encounters
  allDiagnosticReports
    .filter(report => report.encounter == undefined)
    .forEach(report => {
      // If the diagnostic report doesn't have any notes to display, we shouldn't create a table entry for it
      if (!report.presentedForm) return;

      const encounter: FHIREncounter = {
        resourceType: "Encounter",
        id: report.id,
        period: report.effectivePeriod ?? { start: report.effectiveDateTime },
      };
      const participants: Practitioner[] = [];
      const organizations: Organization[] = [];

      const practitionerIds = report.performer
        ?.filter(perf => perf.reference?.startsWith("Practitioner"))
        .flatMap(perf => perf.reference?.split("/").pop() || []);

      practitionerIds?.forEach(practId => {
        const practitioner = bundle.Practitioner?.[practId];

        if (practitioner) {
          participants.push(practitioner as Practitioner);
        }
      });

      const organizationIds = report.performer
        ?.filter(perf => perf.reference?.startsWith("Organization"))
        .flatMap(perf => perf.reference?.split("/").pop() || []);

      organizationIds?.forEach(orgId => {
        const organization = bundle.Organization?.[orgId];

        if (organization) {
          organizations.push(organization as Organization);
        }
      });

      reports.push({
        encounter,
        participants,
        organizations,
        diagnoses: [],
        diagnosticReports: [report],
      });
    });

  return reports;
}

function getReportRowData({
  reports,
  tableFilters,
}: {
  reports: Report[];
  tableFilters: MrFilterSetting | undefined;
}): ReportRowData[] {
  return reports
    ?.filter(report => report.diagnosticReports.length !== 0)
    .map(report => {
      const encounter = report.encounter;

      const reasonForVisitString = report.diagnoses.map(getDiagnosisText).filter(Boolean);

      const typeOfReport = getReportTypeByLoinc(report.diagnosticReports);

      const locations = Array.from(new Set(report.locations?.map(location => location.name))).join(
        ", "
      );

      const organizations = Array.from(new Set(report.organizations?.map(org => org.name))).join(
        ", "
      );

      return {
        id: encounter.id ?? "",
        report,
        typeOfReport,
        reasonForVisit: reasonForVisitString.length
          ? toTitleCase(reasonForVisitString.join(", "))
          : "-",
        location: locations.length > 0 ? locations : organizations,
        date: encounter.period?.start ? dayjs(encounter.period.start).format(ISO_DATE) : "-",
      };
    })
    .filter(row => filterByDate(row.date, tableFilters?.dateFilter))
    .sort((a, b) => {
      if (typeof a === "string" && typeof b === "string") {
        return compare(a, b, tableFilters?.stringFilter);
      }

      return 0;
    });
}

export function getReportTypeByLoinc(diagnosticReports: DiagnosticReport[]): string {
  const type = diagnosticReports.flatMap(report => {
    let reportType: string | undefined;
    let notePresent;

    report.code?.coding?.forEach(coding => {
      const display = coding.display?.toLowerCase().trim();
      if (display === "note") {
        notePresent = true;
      }
      if (display !== UNKNOWN_DISPLAY) {
        reportType = display;
      }
    });

    const text = report.code?.text?.toLowerCase().trim();
    if (!reportType && !notePresent && text !== UNKNOWN_DISPLAY) reportType = text;

    return (reportType && toTitleCase(reportType)) || (notePresent ? "Note" : []);
  });

  const typeDisplay = Array.from(new Set(type)).join(", ");
  return typeDisplay?.length ? typeDisplay : "-";
}
