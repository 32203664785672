import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";

export function SidePanel({
  showPanel,
  setSidePanelContent,
  children,
}: {
  showPanel: boolean;
  setSidePanelContent: () => void;
  children: React.ReactNode;
}) {
  const bg = useColorModeValue("white", "gray.800");

  const useDrawer = useBreakpointValue(
    {
      base: true,
      "2xl": false,
    },
    { ssr: false }
  );

  if (useDrawer) {
    return (
      <Drawer
        id="side-panel"
        isOpen={showPanel}
        size="lg"
        placement="right"
        onClose={setSidePanelContent}
      >
        <DrawerContent
          overflow={"auto"}
          bg={bg}
          borderRadius={10}
          border={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          px={8}
          height={"90vh"}
          marginTop={"5vh"}
        >
          <Box bg={bg} position="sticky" top={0} right={0} zIndex={2}>
            <SidePanelActions setSidePanelContent={setSidePanelContent} />
          </Box>
          {children}
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Box
      id="side-panel"
      hidden={!showPanel}
      height={"calc(100vh - 100px)"}
      width={"35vw"}
      top={50 + 25}
      position="sticky"
      ml={6}
      overflow={"auto"}
      bg={bg}
      borderRadius={10}
      border={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      px={8}
    >
      <Box bg={bg} position="sticky" top={0} right={0} zIndex={2}>
        <SidePanelActions setSidePanelContent={setSidePanelContent} />
      </Box>
      {children}
    </Box>
  );
}

function SidePanelActions({ setSidePanelContent }: { setSidePanelContent: () => void }) {
  return (
    <Flex flexDirection={"row"} pt={2} mb={2} justifyContent={"flex-end"}>
      <IconButton
        background={"transparent"}
        aria-label="Close side panel"
        icon={<RiCloseLine />}
        onClick={setSidePanelContent}
      />
    </Flex>
  );
}
