import { Observation, Organization, Practitioner } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { formatDate, getReferenceResources, getResourcesFromBundle } from "../shared";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayText } from "../shared/occurrences";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import { VitalsChart } from "./chart";
import { GroupedVitals, VitalRowData, getVital, groupVitals, renderBpValue } from "./table-data";

export function VitalContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & VitalRowData) {
  const observations = getResourcesFromBundle<Observation>(mappedConsolidated, "Observation");
  const vitals = getVital(observations);
  const groupedVitals = groupVitals(vitals);
  const selectedVital = groupedVitals.find(
    vital => vital.mostRecentObservation.id === rowContents.id
  );

  if (!selectedVital) {
    return null;
  }

  if (selectedVital.title === "Blood Pressure") {
    return buildBloodPressurePanel(selectedVital, rowContents, mappedConsolidated, isImpersonating);
  }

  const mostRecentVital = selectedVital.mostRecentObservation;

  const valueString = mostRecentVital?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  let vitalObservations: number[] | undefined = undefined;
  let dates: string[] | undefined = undefined;
  if (selectedVital.sortedPoints) {
    vitalObservations = selectedVital.sortedPoints.map(p => p.value);
    dates = selectedVital.sortedPoints.map(p => p.date);
  }

  return (
    <SidePanelContent
      title={"Vital"}
      sourceDocument={{
        id: mostRecentVital?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentVital, null, 2) : undefined}
    >
      {vitalObservations && vitalObservations.length > 1 && dates && (
        <>
          <VitalsChart
            vitalsId={mostRecentVital.id ?? ""}
            vitals={{
              title: selectedVital.title,
              values: vitalObservations,
            }}
            dates={dates.map(formatDate)}
          />
        </>
      )}
      <VitalDisplay
        vital={selectedVital}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function buildBloodPressurePanel(
  selectedVital: GroupedVitals,
  rowContents: VitalRowData,
  mappedConsolidated: MappedConsolidatedResources | undefined,
  isImpersonating?: boolean
) {
  const mostRecentVital = selectedVital.mostRecentObservation;

  const valueString = mostRecentVital?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  let systolic: number[] | undefined = undefined;
  let diastolic: number[] | undefined = undefined;
  let dates: string[] | undefined = undefined;
  if (selectedVital.sortedPoints) {
    systolic = selectedVital.sortedPoints.flatMap(p => p.bp?.systolic || []);
    diastolic = selectedVital.sortedPoints.flatMap(p => p.bp?.diastolic || []);
    dates = selectedVital.sortedPoints.map(p => p.date);
  }

  return (
    <SidePanelContent
      title={selectedVital.title}
      sourceDocument={{
        id: mostRecentVital?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentVital, null, 2) : undefined}
    >
      {systolic && diastolic && dates && (
        <>
          <VitalsChart
            vitalsId={mostRecentVital.id ?? ""}
            vitals={{
              title: "Systolic",
              values: systolic,
            }}
            vitalsSecondary={{
              title: "Diastolic",
              values: diastolic,
            }}
            dates={dates.map(formatDate)}
          />
        </>
      )}
      <VitalDisplay
        vital={selectedVital}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function VitalDisplay({
  vital,
  tableRow,
  mappedConsolidated,
}: {
  vital: GroupedVitals | undefined;
  tableRow: VitalRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!vital) return null;

  const performerOrgReferences = getReferenceResources<Organization>(
    vital.mostRecentObservation.performer,
    "Organization",
    mappedConsolidated
  );

  const performerPractitionerReferences = getReferenceResources<Practitioner>(
    vital.mostRecentObservation.performer,
    "Practitioner",
    mappedConsolidated
  );

  const pointsDescOrder = [...(vital.sortedPoints || [])].reverse();
  return (
    <>
      <DetailsDisplay
        details={{
          measurement: tableRow.observation,
        }}
      />
      {pointsDescOrder && (
        <>
          <SidePanelSection title="Data Points" totalItems={pointsDescOrder.length}>
            {pointsDescOrder.map((p, index) => {
              const valueText = p.bp
                ? renderBpValue(p)
                : p.unit
                ? `${p.value} ${p.unit}`
                : p.value.toString();
              return OccurenceDisplayText(p.date, valueText, index);
            })}
          </SidePanelSection>
        </>
      )}
      <ReferencesDisplay
        organizations={{
          refs: performerOrgReferences,
        }}
        practitioners={{
          refs: performerPractitionerReferences,
        }}
      />
    </>
  );
}
