import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/app";
import { BellIcon, CloseIcon } from "@chakra-ui/icons";

type Notification = {
  id: number;
  text: string;
  isRead: boolean;
  timestamp: Date;
};

// Mock Chakra UI components for preview purposes
export default function Notification() {
  const { state } = useAppContext();
  const user = state.user;

  // State management
  const [hasNotification, setHasNotification] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Simulate notification after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasNotification(true);
      setNotifications([
        { id: 1, text: "Jane Smith admitted to UCSF", isRead: false, timestamp: new Date() },
      ]);
    }, 60000);

    return () => clearTimeout(timer);
  }, []);

  const handleNotificationClick = () => {
    setIsOpen(!isOpen);
    if (hasNotification) {
      setHasNotification(false);
      setNotifications(prevNotifications =>
        prevNotifications.map(notif => ({ ...notif, isRead: true }))
      );
    }
  };

  const handleClearNotification = (id: number) => {
    setNotifications(prevNotifications => prevNotifications.filter(notif => notif.id !== id));
  };

  const isMetriportUser = user?.email?.endsWith("@metriport.com");

  if (!isMetriportUser) return null;

  return (
    <Box position="relative">
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom-end"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            p={2}
            borderRadius="full"
            _hover={{ bg: "gray.100" }}
            position="relative"
            onClick={handleNotificationClick}
            variant="ghost"
          >
            <Icon as={BellIcon} boxSize={5} />
            {hasNotification && (
              <Badge
                position="absolute"
                top={0}
                right={0}
                boxSize={3}
                bg="red.500"
                borderRadius="full"
                transform="translate(25%, -25%)"
              />
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent
          width="72"
          bg="white"
          borderRadius="md"
          boxShadow="lg"
          zIndex={50}
          border="1px"
          borderColor="gray.200"
        >
          <PopoverBody maxH="80" overflowY="auto" p={0}>
            {notifications.length === 0 ? (
              <Text p={4} textAlign="center" color="gray.500">
                No new notifications
              </Text>
            ) : (
              notifications.map(notification => (
                <Flex
                  key={notification.id}
                  p={3}
                  alignItems="start"
                  justifyContent="space-between"
                  bg={notification.isRead ? "white" : "blue.50"}
                  borderBottom="1px"
                  borderColor="gray.100"
                >
                  <Box>
                    <Text fontWeight={notification.isRead ? "normal" : "semibold"} fontSize="sm">
                      {notification.text}
                    </Text>
                    <Text fontSize="xs" color="gray.500" mt={1}>
                      {notification.timestamp.toLocaleTimeString()}
                    </Text>
                  </Box>
                  <IconButton
                    aria-label="Clear notification"
                    icon={<CloseIcon />}
                    size="xs"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: "gray.600" }}
                    onClick={() => handleClearNotification(notification.id)}
                  />
                </Flex>
              ))
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
