import { ChakraProvider, theme } from "@chakra-ui/react";
import {
  AnalyticsProvider,
  BadGateway,
  BadRequest,
  Forbidden,
  GatewayTimeout,
  NetworkError,
  NotFound as NotFoundFromShared,
  PatientConsolidatedStateProvider,
  ServiceUnavailable,
  Timeout,
  Unauthorized,
} from "@metriport/shared-internal";
import { useLogoutFunction, withAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotFound } from "./NotFound";
import Billing from "./components/billing";
import { AppStateProvider } from "./components/contexts/app";
import Feedback from "./components/feedback";
import Home from "./components/home/home";
import Medical from "./components/medical";
import Facilities from "./components/medical/facilities";
import NetworkEntries from "./components/medical/network-entries";
import PatientPage from "./components/medical/patient-page";
import Navbar from "./components/nav/navbar";
import Settings from "./components/settings/settings";
import SubcriptionSuccess from "./components/settings/subscription-success";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import UserAccount from "./components/users/user-account";
import UserManagement from "./components/users/user-management";
import "./index.css";
import { Roles } from "./shared/roles";

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const adminAndOwner = [Roles.owner, Roles.admin];

export const App = withAuthInfo(authInfo => {
  const logoutFn = useLogoutFunction();

  const isImpersonating = authInfo?.isImpersonating;

  return (
    <main>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AnalyticsProvider enabled={!isImpersonating}>
            <AppStateProvider authInfo={authInfo}>
              <PatientConsolidatedStateProvider>
                <Navbar
                  signOut={() => {
                    logoutFn(false);
                  }}
                >
                  <SentryRoutes>
                    <Route path="/" element={<Home></Home>} />
                    <Route
                      path=":sandbox?/network-entries"
                      element={<NetworkEntries></NetworkEntries>}
                    />
                    <Route
                      element={
                        <ProtectedRoute
                          roles={[...adminAndOwner, Roles.developer, Roles.clinical]}
                        />
                      }
                    >
                      <Route path=":sandbox?/patients" element={<Medical />} />
                      <Route path=":sandbox?/facilities" element={<Facilities />} />
                      <Route path=":sandbox?/patient/:patientId" element={<PatientPage />} />
                    </Route>
                    <Route element={<ProtectedRoute roles={[...adminAndOwner, Roles.billing]} />}>
                      <Route path="billing" element={<Billing></Billing>} />
                    </Route>
                    <Route element={<ProtectedRoute roles={[...adminAndOwner, Roles.developer]} />}>
                      <Route path=":sandbox?/developers" element={<Settings />} />
                    </Route>
                    <Route element={<ProtectedRoute roles={[...adminAndOwner]} />}>
                      <Route path="users" element={<UserManagement></UserManagement>} />
                    </Route>
                    <Route path="account" element={<UserAccount></UserAccount>} />
                    <Route
                      path="subscription-successful"
                      element={<SubcriptionSuccess></SubcriptionSuccess>}
                    />
                    <Route path="/feedback/:id" element={<Feedback />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="*" element={<NotFound />} />

                    <Route path="/bad-request" element={<BadRequest />} />
                    <Route path="/bad-gateway" element={<BadGateway />} />
                    <Route path="/forbidden" element={<Forbidden />} />
                    <Route path="/gateway-timeout" element={<GatewayTimeout />} />
                    <Route path="/network-error" element={<NetworkError />} />
                    <Route path="/not-found" element={<NotFoundFromShared />} />
                    <Route path="/service-unavailable" element={<ServiceUnavailable />} />
                    <Route path="/timeout" element={<Timeout />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                  </SentryRoutes>
                </Navbar>
              </PatientConsolidatedStateProvider>
            </AppStateProvider>
          </AnalyticsProvider>
        </ChakraProvider>
      </BrowserRouter>
    </main>
  );
});
