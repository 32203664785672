import { Condition, Patient } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import { isChronicCondition } from "./condition-cell";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import {
  ICD_10_CODE,
  SNOMED_CODE,
  compare,
  createEhrActionColumnDef,
  filterByDate,
  getFirstCodeSpecified,
  getResourcesFromBundle,
  getValidCode,
} from "../shared";
import { ConditionCellRenderer } from "./condition-cell";

dayjs.extend(utc);

export type ConditionRowData = {
  id: string;
  condition: string;
  code: string;
  firstSeen: string;
  lastSeen: string;
  originalData: Condition;
  ehrAction?: string;
  isChronic: boolean;
};

type ConditionOccurrence = {
  rawCondition: Condition;
  start: string | undefined;
  end: string | undefined;
  status: string | undefined;
};

export type GroupedConditions = {
  title: string;
  mostRecentCondition: Condition;
  sortedOccurrences?: ConditionOccurrence[];
  status?: string | undefined;
};

export const conditionTableData = ({
  bundle,
  tableFilters,
  ehrActions,
}: GenerateTableDataParams) => {
  const columnDefs: ColDef<ConditionRowData>[] = [
    { field: "id", hide: true },
    {
      field: "condition",
      cellRenderer: ConditionCellRenderer,
    },
    { field: "code" },
    { field: "firstSeen" },
    { field: "lastSeen", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "originalData", hide: true },
  ];

  if (ehrActions?.write) {
    const columnDef = createEhrActionColumnDef<Condition, ConditionRowData>({
      ehrActions,
      path: "condition",
      filter: getEhrFilter,
    });
    columnDefs.push(columnDef);
  }

  const patient = getResourcesFromBundle<Patient>(bundle, "Patient")[0];
  const patientDob = patient?.birthDate ? dayjs.utc(patient.birthDate).format(ISO_DATE) : undefined;

  const conditions = getResourcesFromBundle<Condition>(bundle, "Condition");
  const groupedConditions = groupConditions(conditions, patientDob);
  const fullRowData = getConditionRowData({
    conditions: groupedConditions,
    tableFilters,
    patientDob,
  });

  return {
    columnDefs,
    rowData: fullRowData,
    alternativeRowData: fullRowData.filter(row => row.isChronic),
  };
};

export function groupConditions(
  conditions: Condition[],
  patientDob: string | undefined
): GroupedConditions[] {
  const results: GroupedConditions[] = [];
  const conditionMap: {
    [k: string]: {
      rawCondition: Condition;
      start: string | undefined;
      end: string | undefined;
      status: string | undefined;
    }[];
  } = {};
  conditions.map(c => {
    let title: string;
    const codings = getValidCode(c.code?.coding);
    const displays = codings.map(coding => coding.display);
    const text = c.code?.text;
    if (displays.length) {
      title = Array.from(new Set(displays)).join(", ");
    } else if (text) {
      title = text;
    } else {
      results.push({ title: "-", mostRecentCondition: c });
      return;
    }
    if (!c.onsetPeriod && !c.onsetDateTime) {
      results.push({ title, mostRecentCondition: c });
      return;
    }

    const conditionPoint = {
      rawCondition: c,
      start: getOnsetTime(c, patientDob),
      end: c.onsetPeriod?.end ? dayjs(c.onsetPeriod.end).format(ISO_DATE) : undefined,
      status: getStatus(c),
    };
    const groupedCondition = conditionMap[title];
    if (groupedCondition) {
      groupedCondition.push(conditionPoint);
    } else {
      conditionMap[title] = [conditionPoint];
    }
  });

  Object.entries(conditionMap).map(([title, values]) => {
    const sortedOccurrences = values.sort((a, b) => {
      const dateA = a.start ? new Date(a.start).getTime() : 0;
      const dateB = b.start ? new Date(b.start).getTime() : 0;

      return dateB - dateA;
    });
    const mostRecent = sortedOccurrences[0];
    if (!mostRecent) return;
    results.push({
      title,
      mostRecentCondition: mostRecent.rawCondition,
      sortedOccurrences,
      status: mostRecent.status,
    });
  });
  return results;
}

export function getStatus(condition: Condition): string | undefined {
  return condition.clinicalStatus?.text ??
    condition.clinicalStatus?.coding?.[0]?.display ??
    condition.clinicalStatus?.coding?.[0]?.code === "55561003"
    ? "Active"
    : condition.clinicalStatus?.coding?.[0]?.code;
}

function getConditionRowData({
  conditions,
  tableFilters,
  patientDob,
}: {
  conditions: GroupedConditions[];
  tableFilters: MrFilterSetting | undefined;
  patientDob: string | undefined;
}): ConditionRowData[] {
  return conditions
    .map(condition => ({
      id: condition.mostRecentCondition.id ?? "-",
      condition: condition.title,
      code: getConditionCode(condition.mostRecentCondition),
      firstSeen: getEarliestSeen(condition.sortedOccurrences, patientDob),
      lastSeen: getOnsetTime(condition.mostRecentCondition) ?? "",
      originalData: condition.mostRecentCondition,
      isChronic: isChronicCondition(condition.mostRecentCondition),
    }))
    .filter(row => filterByDate(row.firstSeen, tableFilters?.dateFilter))
    .sort((a, b) =>
      compare(
        {
          firstSeen: a.firstSeen,
          lastSeen: a.lastSeen,
        },
        {
          firstSeen: b.firstSeen,
          lastSeen: b.lastSeen,
        },
        tableFilters?.stringFilter
      )
    );
}

export function getConditionCode(condition: Condition): string {
  const coding = getFirstCodeSpecified(condition.code?.coding ?? [], [ICD_10_CODE, SNOMED_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

export function getOnsetTime(
  condition: Condition,
  patientDob?: string | undefined
): string | undefined {
  const onsetDateTime = condition.onsetDateTime;
  const onsetPeriodStart = condition.onsetPeriod?.start;
  const onsetPeriodEnd = condition.onsetPeriod?.end;

  const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;

  if (time) {
    const date = dayjs.utc(time).format(ISO_DATE);
    if (patientDob && (date === patientDob || patientDob === "Invalid Date")) return undefined;

    return date;
  }

  return undefined;
}

function getEarliestSeen(
  occurrences: ConditionOccurrence[] | undefined,
  patientDob: string | undefined
): string {
  const earliest = occurrences?.reduce((acc, curr) => {
    if (!acc || (curr.start && acc.start && new Date(curr.start) < new Date(acc.start))) {
      if (curr.start != patientDob) {
        return curr;
      }
    }
    return acc;
  }, null as ConditionOccurrence | null);

  return earliest?.start ?? "-";
}

function getEhrFilter(condition: Condition): boolean {
  const snomedCoding = getFirstCodeSpecified(condition.code?.coding ?? [], [SNOMED_CODE]);
  const startDate = condition.onsetDateTime ?? condition.onsetPeriod?.start;
  return snomedCoding !== undefined && startDate !== undefined;
}
